import EventManager, {Unsubscriber} from "../../event/EventManager";
import RenderContext from "../context/RenderContext";
import {
    EventType,
    IModelUpdatedOnNodeLabelUpdateEvent,
    INodeEvent, NewNodeShowUpdateLabelEvent,
} from "../../event/Event";
import {DiagramEditorUtils} from "../util/DiagramEditorUtils";
import NodeRendererUtils from "../renderer/node/NodeRendererUtils";
import {UPDATE_RESPONSE_STATUS_OK, UpdateResponse} from "../../../components/fields/EditableComponent";
import {Observable} from "rxjs";
import {fromPromise} from "rxjs/internal-compatibility";
import {Area} from "../util/GeometryUtils";
import {IDiagramNodeDto} from "../../apis/diagram/IDiagramNodeDto";
import {IEditMode} from "../editor/IEditMode";

export const MINIMAL_TEXT_CLIENT_BOUND_WIDTH = 200;

export default class NodeLabelManager {

    private eventManager: EventManager;
    private renderContext?: RenderContext;

    private updatedNode?: IDiagramNodeDto;
    private isNodeCreateResult?: boolean;
    private updatePromiseResolve?: (value: UpdateResponse) => void;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_DBLCLICK, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_NODE_LABEL_UPDATE, this.handleModelUpdatedOnNodeLabelUpdateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NEW_NODE_SHOW_UPDATE_LABEL, this.handleNewNodeShowUpdateLabelEvent.bind(this)));
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
    }

    private handleNodeEvent(event: INodeEvent) {
        if (event.type === EventType.NODE_DBLCLICK) {
            if (this.renderContext && this.renderContext.isEdit()) {
                this.updateNodeText(event.node);
            }
        }
    }

    private handleNewNodeShowUpdateLabelEvent(event: NewNodeShowUpdateLabelEvent) {
        if (event.type === EventType.NEW_NODE_SHOW_UPDATE_LABEL) {
            if (NodeLabelManager.shouldUpdateLabelOnNodeCreated(event)) {
                this.isNodeCreateResult = true;
                this.updateNodeText(event.node);
            }
        }
    }

    private static shouldUpdateLabelOnNodeCreated(event: NewNodeShowUpdateLabelEvent) {
        return !event.isUndoRedoResult &&
            event.elementCreated &&
            event.renderedNodesCount === 1;
    }

    private handleModelUpdatedOnNodeLabelUpdateEvent(event: IModelUpdatedOnNodeLabelUpdateEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_NODE_LABEL_UPDATE && this.updatePromiseResolve) {
            this.updatePromiseResolve({
                status: UPDATE_RESPONSE_STATUS_OK,
            });
            this.onNodeUpdateFinished();
        }
    }

    private updateNodeText(node: IDiagramNodeDto) {
        const elementType = this.getElementType(node);
        if (NodeRendererUtils.isTextAware(elementType)) {
            this.updatedNode = node;
            this.showUpdateUI();
        }
    }

    private showUpdateUI() {
        if (this.updatedNode && this.renderContext) {
            const label = DiagramEditorUtils.getNodeLabel(this.updatedNode, this.renderContext.modelManager);
            let nodeTextClientBounds = NodeRendererUtils.getNodeTextClientBounds(this.updatedNode);
            nodeTextClientBounds = NodeLabelManager.ensureMinimalTextClientBounds(nodeTextClientBounds);
            NodeRendererUtils.hideNodeText(this.updatedNode);
            const doUpdate = (label: string) => this.updateNodeLabel(label, this.updatedNode as IDiagramNodeDto, this.isNodeCreateResult === true);
            const doCancel = () => this.cancelUpdate();
            const isMultiRow = DiagramEditorUtils.isNoteNode(this.updatedNode);
            (this.renderContext.renderMode as IEditMode).diagramApi.updateNodeLabel(label, nodeTextClientBounds, isMultiRow, doUpdate, doCancel);
        }
    }

    private updateNodeLabel(label: string, updatedNode: IDiagramNodeDto, isNodeCreateResult: boolean): Observable<UpdateResponse> {
        const promise = new Promise<UpdateResponse>((resolve, reject) => {
            this.updatePromiseResolve = resolve;
        });
        setTimeout(() => this.eventManager.publishEvent({
            type: EventType.NODE_LABEL_UPDATE,
            node: updatedNode,
            label: label,
            isNodeCreateResult: isNodeCreateResult,
        }), 0);

        return fromPromise<UpdateResponse>(promise);
    }

    private cancelUpdate() {
        if (this.updatedNode) {
            NodeRendererUtils.showNodeText(this.updatedNode);
        }
        this.eventManager.publishEvent({
            type: EventType.NODE_LABEL_UPDATE_CANCELLED,
        });
        this.onNodeUpdateFinished();
    }

    private getElementType(node: IDiagramNodeDto) {
        let elementType = null;
        if (this.renderContext && node.elementIdentifier) {
            elementType = this.renderContext.modelManager.getElementType(node.elementIdentifier);
        }
        return elementType;
    }

    private static ensureMinimalTextClientBounds(nodeTextClientBounds: Area) {
        if (nodeTextClientBounds.w < MINIMAL_TEXT_CLIENT_BOUND_WIDTH) {
            nodeTextClientBounds.w = MINIMAL_TEXT_CLIENT_BOUND_WIDTH;
        }
        return nodeTextClientBounds;
    }

    private onNodeUpdateFinished() {
        this.updatedNode = undefined;
        this.updatePromiseResolve = undefined;
        this.isNodeCreateResult = undefined;
    }
}
