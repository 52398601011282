import React, {useContext, useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {CircularProgress} from "@mui/material";
import {KeycloakHolder} from "../../keycloak/KeycloakHolder";
import {useDispatch} from "react-redux";
import {getSaveTranslationsAction} from "../../store/localization/TranslationReducer";
import {getSaveDashboardAction} from "../../store/dashboard/DashboardReducer";
import {createDiagramDefaultsUpdatedAction} from "../../diagram/defaults/store/DiagramDefaultsReducer";
import {getFetchUserSucceededAction} from "../../store/User";
import {LoginPageService} from "./LoginPageService";
import {FetchableResourceType, getResourceFetchAction} from "../../store/common/FetchableResource";
import Constants from "../../common/Constants";
import inaccessibleRouteService from "../../common/routes/service/InaccessibleRouteService";
import {initializeTrackerConfigAction} from "../../store/config/TrackerConfigReducer";
import LocaleContext, {Locale} from "../../common/LocaleContext";
import ConfigurationContext, {Configuration} from "../../common/ConfigurationContext";
import {useNavigate} from "react-router-dom";

const useLoginPageStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }
    })
);

type Props = {
    service: LoginPageService,
}

export default function LoginPage({service}: Props) {
    const classes = useLoginPageStyles();
    const [tokenRetrieved, setTokenRetrieved] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const locale = useContext<Locale>(LocaleContext);
    const configuration = useContext<Configuration>(ConfigurationContext);

    const parsedToken = KeycloakHolder.getParsedToken();
    if (parsedToken != null) {
        if (!tokenRetrieved) {
            setTokenRetrieved(true);

            (async () => {
                const initialData = await service.fetchInitialData(parsedToken.sub as string);

                dispatch(getFetchUserSucceededAction(initialData.userData));
                locale.setLanguage(initialData.language, false);
                dispatch(initializeTrackerConfigAction(initialData.trackerConfig));
                dispatch(getSaveTranslationsAction(initialData.translations));
                dispatch(getSaveDashboardAction(initialData.dashboard));
                dispatch(createDiagramDefaultsUpdatedAction(initialData.diagramDefaults));
                configuration.setFeatureStatuses(initialData.featureStatuses);
                dispatch(getResourceFetchAction(FetchableResourceType.COMMON_COLLECTION_OPTIONS));
                dispatch(getResourceFetchAction(FetchableResourceType.COMMON_LABEL_OPTIONS));
                dispatch(getResourceFetchAction(FetchableResourceType.COMMON_VIEWPOINT_OPTIONS));
                dispatch(getResourceFetchAction(FetchableResourceType.COMMON_STATE_OPTIONS));
                dispatch(getResourceFetchAction(FetchableResourceType.COMMON_TYPE_OPTIONS));
                dispatch(getResourceFetchAction(FetchableResourceType.COMMON_AUTHOR_OPTIONS));

                // last action -> redirect to inaccessible route or dashboard
                const inaccessibleRoute = inaccessibleRouteService.loadAndClear();
                if (inaccessibleRoute && inaccessibleRoute !== Constants.FE_APP_MAIN_ROUTE) {
                    navigate(inaccessibleRoute);
                } else {
                    if (initialData.dashboard.mainWidget.url || initialData.dashboard.mainWidget.diagramIdentifier) {
                        navigate(Constants.FE_APP_DASHBOARD_ROUTE);
                    } else {
                        navigate(Constants.FE_APP_STRUCTURES_ROUTE);
                    }
                }
            })();
        }
    }

    return <div className={classes.root}>
        <div>
            <CircularProgress size={150}/>
        </div>
    </div>
}
