import React, {SyntheticEvent, useCallback, useContext, useEffect, useRef, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import {
    DataGridPro,
    GridColumnOrderChangeParams,
    GridColumns,
    GridColumnVisibilityModel,
    GridRowHeightParams,
    GridRowIdGetter,
    GridRowOrderChangeParams,
    GridRowParams,
    GridRowsProp,
    GridSortModel
} from "@mui/x-data-grid-pro";
import clsx from "clsx";
import GridActionsPanel from "./GridActionsPanel";
import {GridAction} from "./GridAction";
import {getGridPersistentStateUpdateAction, IGridState, PersistentStateId} from "../../store/common/Grid";
import {IApplicationState} from "../../store/Store";
import {Alert, Collapse} from "@mui/material";
import ContextMenu, {ContextMenuItem} from "../ContextMenu";
import {_transl} from "../../store/localization/TranslMessasge";
import {ExtGridColumnMenu} from "./ExtGridColumnMenu";
import {GridPresets} from "./presets/GridPresets";
import {GridPresetsProcessor} from "./presets/GridPresetsProcessor";
import provideGridLocaleText from "./ExtGridLocaleTextProvider";
import {GridColDef} from "@mui/x-data-grid";
import {GridColumnResizeParams} from "@mui/x-data-grid/models/params/gridColumnResizeParams";
import {useDispatch, useSelector} from "react-redux";
import Constants from "../../common/Constants";
import EventManager from "../../common/event/EventManager";
import EventManagerContext from "../../common/event/EventManagerContext";
import {GridPresetsEventType, GridPresetsResetEvent} from "../../pages/main/content/presets/GridPresetsEvents";
import {GridEventType, UpdateSelectedRowsIdsEvent} from "./GridEvents";

const SMALL_HEADER_HEIGHT = 40;
const DEFAULT_HEADER_HEIGHT = 56;

export type AlertType = {
    type: "error" | "success" | "info" | "warning",
    text: string,
}

export enum HeaderRowSize {
    SMALL = "SMALL",
    MIDDLE = "MIDDLE",
}

const ROW_HEIGHT_OF_EMPTY_GRID = 0;
const ROW_HEIGHT = 40;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative",
        },
        dataGrid: {
            minHeight: "47em",
            "&.MuiDataGrid-root .MuiDataGrid-columnHeaders, &.MuiDataGrid-root .MuiDataGrid-columnHeader": {
                backgroundColor: theme.palette.primary.main,
                color: 'white'
            },
            '&.MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiSvgIcon-root': {
                color: "white",
            },
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                outline: 'none',
            },
            '&.MuiDataGrid-root .MuiDataGrid-row:nth-child(even)': {
                backgroundColor: "rgba(61, 73, 119, 0.02)",
            },
            '&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected, &.MuiDataGrid-root .MuiDataGrid-dataContainer .MuiDataGrid-row:nth-child(even).Mui-selected': {
                backgroundColor: "rgba(61, 73, 119, 0.2)",
            },
        },
        alert: {
            "&.MuiAlert-root": {
                padding: theme.spacing(0.5),
                paddingLeft: theme.spacing(2),
            },
            height: "3em",
        },
        alertCollapse: {
            position: "absolute",
            top: "-2.5em",
            left: 0,
            width: "100%",
        },
    }));

export interface ExtGridProps {
    columns: GridColumns,
    presets: GridPresets,
    onPresetsChanged: (presets: GridPresets) => void,
    rows: GridRowsProp,
    rowCount: number,
    paginationMode?: 'client' | 'server',
    getRowId: GridRowIdGetter,
    peristentStateId: PersistentStateId,
    pageSize?: number,

    // resourceId identifies a resource and once it changes (i.e. resourceId and store resourceId differs) then store state is reset.
    resourceId: string,
    rootClass?: string,
    dataGridClass?: string,
    onRowClick?: (param: GridRowParams, event?: React.MouseEvent) => void,
    onRowDoubleClick?: (param: GridRowParams, event: React.MouseEvent) => void,
    onSelectionChanged?: (selectedRowIds: Array<unknown>, selectedRows: Array<unknown>) => void,
    headerRowSize?: HeaderRowSize,
    alert?: AlertType,
    loading?: boolean,
    menuItems?: ContextMenuItem<string>[],
    id?: string,

    onCustomColumnAddClick?: (event: SyntheticEvent) => void,
    onCustomColumnRemoveClick?: (colDef: GridColDef, event: SyntheticEvent) => void,

    hideActionButtonsPanel?: boolean,
    actions?: GridAction[],

    actionButtonsPanelRootClass?: string,
    bulkActionsOpenedPermanently?: boolean,

    initialPageReset?: boolean,

    onRowScrollEnd?: () => void,

    rowReordering?: boolean,
    onRowReorderChange?: (params: GridRowOrderChangeParams) => void,

    onSortModelChange?: (gridSortModel: GridSortModel) => void,
    sortingMode? : 'client' | 'server',

    disableMultipleSelection? : boolean,
}

export default function ExtGrid(props: ExtGridProps) {

    const classes = useStyles();
    const persistentState = useSelector((state: IApplicationState) => state.pages.gridPersistentState);

    const eventManager = useContext<EventManager>(EventManagerContext);

    const presetsExtractor = useRef<GridPresetsProcessor>(new GridPresetsProcessor());
    const lastRowEnterRowId = useRef<string>();
    const initialPageReset = useRef<boolean>(props.initialPageReset ? true : false);

    const [contextMenuIsOpen, setContextMenuIsOpen] = useState<boolean>(false);
    const [menuTop, setMenuTop] = useState<number>();
    const [menuLeft, setMenuLeft] = useState<number>();

    const {onPresetsChanged, dataGridClass, rootClass, onRowScrollEnd} = props;
    const {id, rows, rowCount, getRowId, onRowClick, onRowDoubleClick, loading, onSortModelChange, presets} = props;
    const paginationMode = props.paginationMode ? props.paginationMode : 'server';
    const headerRowSize = props.headerRowSize ? props.headerRowSize : HeaderRowSize.SMALL;
    const pageSize = props.pageSize ? props.pageSize : Constants.GRID_DEFAULT_PAGE_SIZE;
    const {actions, hideActionButtonsPanel, actionButtonsPanelRootClass, bulkActionsOpenedPermanently} = props;
    const {peristentStateId, resourceId, onSelectionChanged, menuItems} = props;

    const {onCustomColumnAddClick, onCustomColumnRemoveClick, alert} = props;
    const checkedMenuItems = menuItems ? menuItems : [];
    const mouseMenuTop = menuTop ? menuTop : 0;

    const mouseMenuLeft = menuLeft ? menuLeft : 0;
    const selectedPage = initSelectedPageAndUpdateState();
    const selectedRowIds = getPersistentStateSelectedRowIds();
    const selectedRows = getPersistentStateSelectedRows(selectedRowIds);
    const checkboxSelection = getPersistentStateCheckboxSelection();
    const selectedRowIdsCount = selectedRowIds?.length || 0;
    const isLoading = loading != null ? loading : false;

    const headerHeight = (headerRowSize != null && headerRowSize === HeaderRowSize.MIDDLE) ? DEFAULT_HEADER_HEIGHT : SMALL_HEADER_HEIGHT;

    const dispatch = useDispatch();

    const updatePersistentState = useCallback((persitentStateId: PersistentStateId, state: IGridState) => {
        dispatch(getGridPersistentStateUpdateAction(persitentStateId, state));
    }, [dispatch]);

    const resetState = useCallback(() => {
        updatePersistentState(peristentStateId, createPersistentState(resourceId, 0, [], false));
    }, [peristentStateId, resourceId, updatePersistentState]);

    const sortModel = presetsExtractor.current.getSortModel(presets);

    useEffect(() => {
        var unsubscribe = eventManager.subscribeListener(GridEventType.UPDATE_SELECTED_ROWS_IDS,
            (event: UpdateSelectedRowsIdsEvent) => {
                updatePersistentState(peristentStateId, createPersistentState(resourceId, selectedPage, event.selectedRowIds, checkboxSelection));
                });
        return () => unsubscribe();
    }, [eventManager, checkboxSelection, peristentStateId, resourceId, selectedPage, updatePersistentState]);

    useEffect(() => {
        const unsubscribe = eventManager.subscribeListener(GridPresetsEventType.PRESETS_RESET,
            (event: GridPresetsResetEvent) => {
                if (onSortModelChange) {
                    onSortModelChange([]);
                }
            });
        return () => {
            unsubscribe();
        };
    }, [eventManager, onSortModelChange]);

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            if (resourceId !== persistentState[peristentStateId]?.resourceId) {
                resetState();
            }
        }

        return () => {
            isMounted = false;
        };

    }, [resourceId, persistentState, peristentStateId, resetState]);


    return (
        <div className={computeRootClasses()} id={id}>
            <ContextMenu menuItems={checkedMenuItems}
                         data={lastRowEnterRowId.current}
                         open={contextMenuIsOpen}
                         menuTop={mouseMenuTop}
                         menuLeft={mouseMenuLeft}
                         onClose={() => setContextMenuIsOpen(false)}
            />
            {alert &&
                <Collapse in={true} className={classes.alertCollapse}>
                    <Alert className={classes.alert} severity={alert.type}>{alert.text}</Alert>
                </Collapse>
            }
            <GridActionsPanel hidden={hideActionButtonsPanel || false}
                              rootClass={actionButtonsPanelRootClass}
                              actionButtons={actions || []}
                              onActionButtonClicked={button => onActionButtonClicked(button)}
                              rowsCount={rowCount}
                              selectedRows={selectedRows}
                              selectedRowsCount={selectedRowIdsCount}
                              bulkActionsOpened={checkboxSelection}
                              enableBulkActionsPermanently={bulkActionsOpenedPermanently === true}
                              onBulkActionsOpened={() => {
                                  updatePersistentState(peristentStateId, createPersistentState(resourceId, selectedPage, [], true));
                                  onSelectionChanged && onSelectionChanged([], []);
                              }}
                              onBulkActionsClosed={() => {
                                  updatePersistentState(peristentStateId, createPersistentState(resourceId, selectedPage, [], false));
                                  onSelectionChanged && onSelectionChanged([], []);
                              }}
                              gridPersistendStateId={peristentStateId}
            />
            <DataGridPro className={computeDataGridClasses()}
                         getRowId={getRowId}
                         rows={rows}
                         columns={presetsExtractor.current.applyGridPresets(presets, props.columns)}
                         paginationMode={paginationMode}
                         rowCount={rowCount}
                         rowsPerPageOptions={[pageSize]}
                         pageSize={pageSize}
                         keepNonExistentRowsSelected={paginationMode === 'server' ? true : undefined}
                         headerHeight={headerHeight}
                         rowHeight={ROW_HEIGHT_OF_EMPTY_GRID}
                         getRowHeight={({id}: GridRowHeightParams) => {
                             if (id) {
                                 return ROW_HEIGHT;
                             }
                             return null;
                         }}
                         checkboxSelection={checkboxSelection}
                         components={{
                             ColumnMenu: ExtGridColumnMenu,
                         }}
                         componentsProps={{
                             row: {
                                 onMouseOver: (event: React.MouseEvent) => {
                                     lastRowEnterRowId.current = event.currentTarget.getAttribute('data-id') as string;
                                 },
                                 onContextMenu: (event: React.MouseEvent) => {
                                     event.preventDefault();
                                     setContextMenuIsOpen(true);
                                     setMousePosition(event.clientY, event.clientX);
                                 }
                             },
                             columnMenu: {
                                 onCustomColumnAddClick: onCustomColumnAddClick,
                                 onCustomColumnRemoveClick: onCustomColumnRemoveClick,
                             }
                         }}
                         localeText={provideGridLocaleText(_transl)}
                         onRowClick={onRowClick}
                         onRowDoubleClick={onRowDoubleClick}
                         selectionModel={selectedRowIds}
                         onSelectionModelChange={(selectedRowIds) => {
                             updatePersistentState(peristentStateId, createPersistentState(resourceId, selectedPage, selectedRowIds, checkboxSelection));
                             if (onSelectionChanged) {
                                 onSelectionChanged(selectedRowIds, getPersistentStateSelectedRows(selectedRowIds))
                             }
                         }}
                         pagination={false}
                         page={selectedPage}
                         onPageChange={(page: number) => {
                             updatePersistentState(peristentStateId, createPersistentState(resourceId, page, selectedRowIds, checkboxSelection));
                         }}
                         loading={isLoading}
                         onColumnOrderChange={handleOnColumnOrderChange}
                         onColumnVisibilityModelChange={handleOnColumnVisibilityModelChanged}
                         onColumnWidthChange={handleOnColumnResize}
                         onRowsScrollEnd={onRowScrollEnd}
                         hideFooter={true}
                         rowReordering={props.rowReordering}
                         onRowOrderChange={props.onRowReorderChange}
                         onSortModelChange={(gridSortModel) => {
                             if (props.onSortModelChange) {
                                 props.onSortModelChange(gridSortModel);
                             }
                             handleOnSortModelChange(gridSortModel);
                         }}
                         sortModel={sortModel}
                         sortingMode={props.sortingMode}
                         disableMultipleSelection={props.disableMultipleSelection}
            />
        </div>
    )

    function initSelectedPageAndUpdateState() {
        let selectedPage = getPersistentStatePage() || 0;
        if (initialPageReset.current) {
            selectedPage = 0;
            updatePersistentStatePage(selectedPage);
            initialPageReset.current = false;
        }
        return selectedPage;
    }

    function computeRootClasses() {
        const rootClasses = {
            [classes.root]: true,
            [rootClass as string]: rootClass != null,
        }
        return clsx(rootClasses);
    }

    function computeDataGridClasses() {
        const dataGridClasses = {
            [classes.dataGrid]: true,
            [dataGridClass as string]: dataGridClass != null,
        }
        return clsx(dataGridClasses);
    }

    function onActionButtonClicked(button: GridAction) {
        if (button.onClick) {
            const selectedRowIds = getPersistentStateSelectedRowIds();
            button.onClick(selectedRowIds, getPersistentStateSelectedRows(selectedRowIds));
        }
    }

    function getPersistentStatePage() {
        const page = persistentState[peristentStateId]?.page;
        return page != null ? page : undefined;
    }

    function updatePersistentStatePage(newPage: number) {
        updatePersistentState(peristentStateId,
            createPersistentState(peristentStateId, newPage,
                persistentState[peristentStateId]?.selectedRowIds as Array<any>,
                persistentState[peristentStateId]?.checkboxSelection as boolean));
    }

    function getPersistentStateSelectedRowIds() {
        return persistentState[peristentStateId]?.selectedRowIds || [];
    }

    function getPersistentStateSelectedRows(selectedRowIds: Array<unknown>) {
        return rows.filter(row => selectedRowIds.indexOf(getRowId(row)) !== -1);
    }

    function getPersistentStateCheckboxSelection() {
        if (bulkActionsOpenedPermanently === true) {
            return true;
        } else {
            const checkboxSelection = persistentState[peristentStateId]?.checkboxSelection;
            return checkboxSelection != null ? checkboxSelection : false;
        }
    }

    function createPersistentState(resourceId: string, page: number, selectedRowIds: Array<any>, checkboxSelection: boolean) {
        return {
            resourceId: resourceId,
            page: page,
            selectedRowIds: selectedRowIds,
            checkboxSelection: checkboxSelection
        }
    }

    function setMousePosition(menuTop: number, menuLeft: number) {
        setMenuTop(menuTop);
        setMenuLeft(menuLeft);
    }

    function handleOnSortModelChange(gridSortModel: GridSortModel): void {
        const newPresets = presetsExtractor.current.changeColumnSort(presets, gridSortModel);
        onPresetsChanged(newPresets);
    }

    function handleOnColumnOrderChange(gridColumnOrderChangeParams: GridColumnOrderChangeParams): void {
        const newPresets = presetsExtractor.current.changeColumnOrder(presets, gridColumnOrderChangeParams.oldIndex, gridColumnOrderChangeParams.targetIndex);
        onPresetsChanged(newPresets);
    }

    function handleOnColumnVisibilityModelChanged(newGridColumnVisibilityModel: GridColumnVisibilityModel): void {
        const newPresets = presetsExtractor.current.applyColumnVisibilityModelOnPresets(newGridColumnVisibilityModel, presets);
        onPresetsChanged(newPresets);
    }

    function handleOnColumnResize(gridColumnResizeParams: GridColumnResizeParams): void {
        const newPresets = presetsExtractor.current.applyColumnResize(presets, gridColumnResizeParams.colDef.field, gridColumnResizeParams.width);
        onPresetsChanged(newPresets);
    }

}
