import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {IApplicationState} from "../../../../store/Store";
import {useDispatch, useSelector} from "react-redux";
import {FetchStatusType} from "../../../../store/common/FetchableResource";
import RouteDefinitionUtils from "../../../../common/routedefinition/RouteDefinitionUtils";
import {PersistentStateId} from "../../../../store/common/Grid";
import {getFilterRefetchAction, IFilter} from "../../../../store/diagrams/Diagrams";
import {ActionButtonType, EnabledPolicy, GridAction} from "../../../../components/grid/GridAction";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {ButtonId} from "../../../General";
import Api from "../../../../common/Api";
import AlertDialog, {AlertDialogType} from "../../../../components/dialogs/AlertDialog";
import {GridDef} from "./RenderMode";
import {diagramsMenu} from "../../../../common/menudefinition/MenuDefinitionUtils";
import ElementListDialog from "../elements/ElementListDialog";
import {from, Observable} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../../../../components/dialogs/ConfirmationDialog";
import AddIcon from "@mui/icons-material/Add";
import CreateDiagramDialog from "./create/CreateDiagramDialog";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import {ContextMenuItem} from "../../../../components/ContextMenu";
import DescriptionIcon from "@mui/icons-material/Description";
import Constants from "../../../../common/Constants";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "./DiagramTranslationKey";
import {CommonTranslation} from "../CommonTranslation";
import {ILabelDto} from "../../../../common/apis/label/ILabelDto";
import constructDiagramController from "./controller/DiagramController";
import {UserDto} from "../../../../common/apis/user/UserDto";
import DiagramAclDto from "../../../../common/apis/diagram/DiagramAclDto";
import EditIcon from "@mui/icons-material/Edit";
import {FileCopy, Link, LocalOffer, MoreHoriz, Shield, ViewList} from "@mui/icons-material";
import Snackbar from "../snackbar/Snackbar";
import {ElementTranslationKey} from "../elements/ElementTranslationKey";
import DiagramDetailDialog from "./detail/DiagramDetailDialog";
import {DiagramsGridAction, DiagramsGridActionType} from "./DiagramsGridAction";
import RenderMode from "../../../../common/diagrameditor/context/RenderMode";
import {DiagramDuplicateDialog} from "./DiagramDuplicateDialog";
import {DiagramInfoDto} from "../../../../common/apis/diagram/DiagramInfoDto";
import ExtGrid from "../../../../components/grid/ExtGrid";
import {GridPresets} from "../../../../components/grid/presets/GridPresets";
import AddBulkLabelsToDiagramsDialog from "./bulk/AddBulkLabelsToDiagramsDialog";
import AddBulkCollectionsToDiagramsDialog from "./bulk/AddBulkCollectionsToDiagramsDialog";
import RemoveBulkLabelsFromDiagramsDialog from "./bulk/RemoveBulkLabelsFromDiagramsDialog";
import RemoveBulkCollectionsFromDiagramsDialog from "./bulk/RemoveBulkCollectionsFromDiagramsDialog";
import {GridPresetsProcessor} from "../../../../components/grid/presets/GridPresetsProcessor";
import {diagramEditorPage} from "../../../../common/routes/AppRoutes";
import {useParams} from "react-router-dom";
import {
    BulkCollectionsDiagramsRemovedEvent,
    BulkLabelsDiagramsRemovedEvent,
    GridEventType
} from "../../../../components/grid/GridEvents";
import EventManager from "../../../../common/event/EventManager";
import EventManagerContext from "../../../../common/event/EventManagerContext";

interface DiagramsGridProps {
    filter: IFilter,
    presets?: GridPresets,
    onPresetsChanged: (presets: GridPresets) => void,
}

export function DiagramsGrid(props: DiagramsGridProps) {

    const dispatch = useDispatch();
    const controller = constructDiagramController();
    const {id} = useParams();
    const eventManager = useContext<EventManager>(EventManagerContext);

    const {filter, presets, onPresetsChanged} = props;

    const [clickedButtonId, setClickedButtonId] = useState<ButtonId>();
    const [selectedRows, setSelectedRows] = useState<DiagramInfoDto[]>([]);
    const [, setDiagramAcls] = useState<DiagramAclDto[]>();
    const [diagramsElementListDialog, setDiagramsElementListDialog] = useState<{
        elementDtos: Array<ElementDto>
    }>();
    const [alertDialog, setAlertDialog] = useState<{
        open: boolean,
        type: AlertDialogType,
        title: string,
        text: string
    }>();
    const [confirmationDialog, setConfirmationDialog] = useState<{
        open: boolean,
        onConfirm: () => void,
        onCancel: () => void,
        title: string,
        confirmationText: string,
    }>();
    const [createDiagramDialogOpened, setCreateDiagramDialogOpened] = useState<boolean>();
    const [duplicateDialog, setDuplicateDialog] = useState<{
        open: boolean,
        diagramId: string,
        diagramName?: string
    }>();
    const [diagramDetailDialog, setDiagramDetailDialog] = useState<{ open: boolean, diagramId: string }>();
    const [isAddBulkLabelsToDiagramsDialogVisible, setIsAddBulkLabelsToDiagramsDialogVisible] = useState<boolean>(false);
    const [isRemoveBulkLabelsFromDiagramsDialogVisible, setIsRemoveBulkLabelsFromDiagramsDialogVisible] = useState<boolean>(false);
    const [isAddBulkCollectionsToDiagramsDialogVisible, setIsAddBulkCollectionsToDiagramsDialogVisible] = useState<boolean>(false);
    const [isRemoveBulkCollectionsFromDiagramsDialogVisible, setIsRemoveBulkCollectionsFromDiagramsDialogVisible] = useState<boolean>(false);

    const fetchStatus = useSelector((state: IApplicationState) => state.pages.diagrams.grid.fetchStatus.status);
    const rows = useSelector((state: IApplicationState) => state.pages.diagrams.grid.resource);
    const labelOptions = useSelector((state: IApplicationState) => state.pages.common.options.labels.resource);
    const collectionOptions = useSelector((state: IApplicationState) => state.pages.common.options.collections.resource);
    const user = useSelector((state: IApplicationState) => state.user.userData as UserDto);
    const refetchFilter = () => {
        dispatch(getFilterRefetchAction());
    };

    const presetsExtractor = useRef<GridPresetsProcessor>(new GridPresetsProcessor());

    const createNormalizedPresets = (presets?: GridPresets) => {
        const normalizationResult = presetsExtractor.current.normalizePresets(GridDef.getGridColDef(), presets);
        return normalizationResult.normalizedPresets;
    };

    const gridPresets = createNormalizedPresets(presets);

    useEffect(() => {
        if (id) {
            setDiagramDetailDialog(prev => ({...prev, open: true, diagramId: id}));
        }
    },[id])

    const updateSelectedDiagrams = useCallback(() => {
        Api.diagrams.doSearch(filter).toPromise()
            .then((response) => {
                const diagrams = response.response as Array<DiagramInfoDto>;
                const remainingSelectedDiagrams = selectedRows.filter(diagram =>
                    diagrams.some(elementDto => elementDto.identifier === diagram.identifier)
                );
                const remainingSelectedDiagramsIds = remainingSelectedDiagrams.map(element => element.identifier);
                setSelectedRows(remainingSelectedDiagrams);
                eventManager.publishEvent({
                    type: GridEventType.UPDATE_SELECTED_ROWS_IDS,
                    selectedRowIds: remainingSelectedDiagramsIds,
                });
            });
    }, [eventManager, selectedRows, filter]);

    useEffect(() => {
        var unsubscribe = eventManager.subscribeListener(GridEventType.BULK_COLLECTIONS_DIAGRAMS_REMOVED,
            (event: BulkCollectionsDiagramsRemovedEvent) => {
                if (filter.collections && filter.collections.length > 0) {
                    updateSelectedDiagrams();
                }
            });
        return () => unsubscribe();
    }, [eventManager, filter, updateSelectedDiagrams]);

    useEffect(() => {
        var unsubscribe = eventManager.subscribeListener(GridEventType.BULK_LABELS_DIAGRAMS_REMOVED,
            (event: BulkLabelsDiagramsRemovedEvent) => {
                if (filter.labels && filter.labels.length > 0) {
                    updateSelectedDiagrams();
                }
            });
        return () => unsubscribe();
    }, [eventManager, filter, updateSelectedDiagrams]);

    function onDiagramCreated(diagramId: string) {
        refetchFilter();
        closeCreateDiagramDialog();
        openDiagramDetail(diagramId);
    }

    function openDiagramDetail(rowId?: string) {
        if (rowId) {
            setDiagramDetailDialog(prev => ({...prev, open: true, diagramId: rowId}));
        }
    }

    function closeDiagramDetail(reload: boolean) {
        setDiagramDetailDialog(undefined);
        if (reload) {
            updateSelectedDiagrams();
        }
    }

    function closeCreateDiagramDialog() {
        setCreateDiagramDialogOpened(false);
    }

    function copyDiagramUrl(selectedRowId?: string) {
        if (selectedRowId) {
            const path = RouteDefinitionUtils.resolveDiagramDetailPath(selectedRowId);
            const url = Constants.attachAppHost(path);
            from(navigator.clipboard.writeText(url)).subscribe(
                () => {
                    Snackbar.success(_transl(ElementTranslationKey.URL_COPIED));
                },
                () => {
                }
            )
        }
    }

    function addBulkLabels(selectedRowIds: Array<string>, selectedRows: DiagramInfoDto[]) {
        setClickedButtonId(ButtonId.ADD_BULK_LABELS);
        setSelectedRows(selectedRows);
        setIsAddBulkLabelsToDiagramsDialogVisible(true);
    }

    function removeBulkLabels(selectedRowIds: Array<string>, selectedRows: DiagramInfoDto[]) {
        setClickedButtonId(ButtonId.REMOVE_BULK_LABELS);
        setSelectedRows(selectedRows);
        setIsRemoveBulkLabelsFromDiagramsDialogVisible(true);
    }

    function getAvailableLabelOptions(selectedRows: DiagramInfoDto[]): ILabelDto[] {
        let availableLabelOptions = labelOptions;
        availableLabelOptions = filterOnlyAssignedLabels(availableLabelOptions, selectedRows);
        if (canUpdatePublicLabelsForAllDiagrams(selectedRows)) {
            return availableLabelOptions
        } else {
            return availableLabelOptions.filter(value => !value.publicLabel);
        }
    }

    function filterOnlyAssignedLabels(labelOptions: ILabelDto[], selectedRows: DiagramInfoDto[]): ILabelDto[] {
        let assignedLabelsCodesSet = getAssignedLabelsSet(selectedRows);
        return labelOptions.filter(labelDto => assignedLabelsCodesSet.has(labelDto.code));
    }

    function getAssignedLabelsSet(selectedRows: DiagramInfoDto[]) {
        let assignedLabelsCodes = selectedRows.flatMap(diagramInfoDto => diagramInfoDto.labels).map(label => label.code);
        return new Set(assignedLabelsCodes);
    }

    function canUpdatePublicLabelsForAllDiagrams(selectedRows: DiagramInfoDto[]): boolean {
        let DiagramInfoDto = selectedRows.find(value => !value.acl.canUpdatePublicLabels);
        return DiagramInfoDto === undefined;
    }

    function addBulkCollections(selectedRowIds: Array<string>, selectedRows: DiagramInfoDto[]) {
        setClickedButtonId(ButtonId.ADD_BULK_COLLECTIONS);
        setSelectedRows(selectedRows);
        setIsAddBulkCollectionsToDiagramsDialogVisible(true);
    }

    function removeBulkCollections(selectedRowIds: Array<string>, selectedRows: DiagramInfoDto[]) {
        if (getSelectedCollectionOptions(selectedRows).length === 0) {
            showAlertDialog(_transl(DiagramTranslationKey.GRID_REMOVE_BULK_COLLECTIONS_ALERT_TITLE), _transl(DiagramTranslationKey.GRID_REMOVE_BULK_COLLECTIONS_ALERT_TEXT));
        } else {
            setClickedButtonId(ButtonId.REMOVE_BULK_COLLECTIONS);
            setSelectedRows(selectedRows);
            setIsRemoveBulkCollectionsFromDiagramsDialogVisible(true);
        }
    }

    function showDeleteDiagramDialog(selectedRowId: string) {
        setClickedButtonId(ButtonId.DELETE_DIAGRAM);
        setConfirmationDialog(prev => ({
            ...prev,
            open: true,
            title: _transl(DiagramTranslationKey.GRID_DELETE_DIAGRAM_TITLE),
            confirmationText: _transl(DiagramTranslationKey.GRID_DELETE_DIAGRAM_CONFIRMATION),
            onConfirm: () => deleteDiagram(selectedRowId),
            onCancel: () => closeDeleteDiagramDialog()
        }))
    }

    function showDeleteDiagramsDialog(selectedRowIds: Array<string>) {
        setClickedButtonId(ButtonId.DELETE_DIAGRAMS);
        setConfirmationDialog(prev => ({
            ...prev,
            open: true,
            title: _transl(DiagramTranslationKey.GRID_DELETE_DIAGRAMS_TITLE),
            confirmationText: _transl(DiagramTranslationKey.GRID_DELETE_DIAGRAMS_CONFIRMATION),
            onConfirm: () => deleteDiagrams(selectedRowIds),
            onCancel: () => closeDeleteDiagramDialog(),
        }))
    }

    function canCreateDiagrams() {
        return user.userAcl.canCreateDiagrams;
    }

    function openDuplicateDialog(selectedDiagram: DiagramInfoDto) {
        setDuplicateDialog(prev => ({
            ...prev,
            open: true,
            diagramId: selectedDiagram.identifier,
            diagramName: selectedDiagram.name,
        }))
    }

    function onDiagramDuplicated(identifier: string) {
        closeDuplicateDiagramDialog();
        onDiagramCreated(identifier);
    }

    function closeDuplicateDiagramDialog() {
        setDuplicateDialog(undefined);
    }

    function loadDiagramsElementList(selectedRowIds: Array<string>) {
        Api.diagrams.getElementListForDiagramIds(selectedRowIds).subscribe(
            (response) => {
                setDiagramsElementListDialog(prev => ({
                    ...prev,
                    elementDtos: response.response,
                }))
            },
            (error) => showAlertDialog(_transl(DiagramTranslationKey.GRID_DIAGRAM_ELEMENT_LIST_TITLE),
                _transl(DiagramTranslationKey.GRID_DIAGRAM_ELEMENT_LIST_TEXT), AlertDialogType.ERROR)
        )
    }

    function showCreateDiagramDialog() {
        setCreateDiagramDialogOpened(true);
    }

    function showDiagramEditorDialog(id: string, edit: boolean) {
        const queryParams = [{name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.SHOW_EDITOR), value: id}];
        if (edit) {
            queryParams.push({
                name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.EDITOR_MODE),
                value: RenderMode.EDIT
            })
        }
        window.open(RouteDefinitionUtils.resolvePath(diagramEditorPage, queryParams), '_blank');
    }

    function showBulkOperationFailedAlert(clickedButtonId: ButtonId) {
        if (clickedButtonId === ButtonId.DELETE_DIAGRAM) {
            showAlertDialog(_transl(DiagramTranslationKey.GRID_DELETE_DIAGRAM_FAILED_ALERT_TITLE), _transl(DiagramTranslationKey.GRID_DELETE_DIAGRAM_FAILED_ALERT_TEXT), AlertDialogType.ERROR);
        }
        if (clickedButtonId === ButtonId.DELETE_DIAGRAMS) {
            showAlertDialog(_transl(DiagramTranslationKey.GRID_DELETE_DIAGRAMS_FAILED_ALERT_TITLE), _transl(DiagramTranslationKey.GRID_DELETE_DIAGRAMS_FAILED_ALERT_TEXT), AlertDialogType.ERROR);
        }
    }

    function callApiForDeleteDiagramOperations(apiCall: () => Observable<AjaxResponse>) {
        apiCall().subscribe(
            () => {
                refetchFilter();
                setConfirmationDialog(undefined);
            },
            () => {
                setConfirmationDialog(undefined);
                showBulkOperationFailedAlert(clickedButtonId as ButtonId);
            }
        );
    }

    function getSelectedCollectionOptions(selectedRows: DiagramInfoDto[]) {
        return collectionOptions.filter(collectionDto => selectedRows.flatMap(diagramSearchDto => diagramSearchDto.collections)
            .map(collection => collection.code)
            .indexOf(collectionDto.code) !== -1);
    }

    function showAlertDialog(title: string, text: string, type: AlertDialogType = AlertDialogType.INFO) {
        setAlertDialog(prev => ({
            ...prev,
            open: true,
            type: type,
            title: title,
            text: text,
        }));
    }

    function deleteDiagram(selectedRowId: string) {
        callApiForDeleteDiagramOperations(() => Api.diagrams.deleteDiagram(selectedRowId));
    }

    function deleteDiagrams(selectedRowIds: Array<string>) {
        callApiForDeleteDiagramOperations(() => Api.diagrams.deleteDiagrams(selectedRowIds));
    }

    function closeDeleteDiagramDialog() {
        setConfirmationDialog(undefined);
    }

    function setDiagramAclsFromSelectedRows(selectedRowIds: Array<unknown>) {
        const diagrams = rows.filter((row) => selectedRowIds.indexOf(row.identifier) !== -1);
        if (diagrams) {
            const diagramAcls = diagrams.map((diagram) => diagram.acl);
            setDiagramAcls(diagramAcls);
        }
    }

    const menuItems: ContextMenuItem<string>[] = [
        {
            icon: <DescriptionIcon/>,
            label: _transl(CommonTranslation.SHOW_DETAIL),
            onClick: (selectedRowId?: string) => openDiagramDetail(selectedRowId)
        },
        {
            icon: <DeleteIcon/>,
            label: _transl(CommonTranslation.REMOVE),
            onClick: (selectedRowId?: string) => selectedRowId && showDeleteDiagramDialog(selectedRowId)
        },
        {
            icon: <Link/>,
            label: _transl(CommonTranslation.COPY_URL),
            onClick: (selectedRowId?: string) => copyDiagramUrl(selectedRowId)
        },
    ];


    return (
        <React.Fragment>
            {createDiagramDialogOpened && <CreateDiagramDialog isOpened={true}
                                                               onDialogClosed={() => setCreateDiagramDialogOpened(false)}
                                                               onDiagramCreated={(identifier) => onDiagramCreated(identifier)}
            />}
            {duplicateDialog && <DiagramDuplicateDialog open={duplicateDialog.open}
                                                        originalDiagramId={duplicateDialog.diagramId}
                                                        originalDiagramName={duplicateDialog.diagramName}
                                                        onClosed={() => closeDuplicateDiagramDialog()}
                                                        onDialogDuplicated={(identifier) => onDiagramDuplicated(identifier)}/>
            }
            {confirmationDialog && <ConfirmationDialog title={confirmationDialog.title}
                                                       confirmationText={confirmationDialog.confirmationText}
                                                       open={true}
                                                       onConfirm={confirmationDialog.onConfirm}
                                                       onReject={confirmationDialog.onCancel}
            />}
            {diagramsElementListDialog && <ElementListDialog isOpened={true}
                                                             dialogTitle={_transl(DiagramTranslationKey.ELEMENT_LIST_DIALOG_TITLE)}
                                                             elements={diagramsElementListDialog.elementDtos}
                                                             onDialogClosed={() => setDiagramsElementListDialog(undefined)}
            />}
            {alertDialog && alertDialog.open && <AlertDialog open={true}
                                                             onClose={() => setAlertDialog(undefined)}
                                                             title={alertDialog.title}
                                                             text={alertDialog.text}
                                                             type={alertDialog.type}
            />}
            <AddBulkLabelsToDiagramsDialog isOpened={isAddBulkLabelsToDiagramsDialogVisible}
                                           onClosed={() => setIsAddBulkLabelsToDiagramsDialogVisible(false)}
                                           diagrams={selectedRows}
                                           refetchFilter={refetchFilter}/>
            <RemoveBulkLabelsFromDiagramsDialog isOpened={isRemoveBulkLabelsFromDiagramsDialogVisible}
                                                onClosed={() => setIsRemoveBulkLabelsFromDiagramsDialogVisible(false)}
                                                diagrams={selectedRows}
                                                refetchFilter={refetchFilter}/>
            <AddBulkCollectionsToDiagramsDialog isOpened={isAddBulkCollectionsToDiagramsDialogVisible}
                                                onClosed={() => setIsAddBulkCollectionsToDiagramsDialogVisible(false)}
                                                diagrams={selectedRows}
                                                refetchFilter={refetchFilter}/>
            <RemoveBulkCollectionsFromDiagramsDialog isOpened={isRemoveBulkCollectionsFromDiagramsDialogVisible}
                                                     onClosed={() => setIsRemoveBulkCollectionsFromDiagramsDialogVisible(false)}
                                                     diagrams={selectedRows}
                                                     refetchFilter={refetchFilter}/>
            {diagramDetailDialog && <DiagramDetailDialog opened={diagramDetailDialog.open}
                                                         diagramId={diagramDetailDialog.diagramId}
                                                         onClosed={closeDiagramDetail}/>
            }
            <ExtGrid
                columns={GridDef.getGridColDef()}
                rows={rows}
                rowCount={rows.length}
                pageSize={Constants.LARGE_GRID_PAGE_SIZE}
                getRowId={row => row.identifier}
                menuItems={menuItems}
                actions={[
                    GridAction.detailButtonBuilder()
                        .onClick((selectedRowIds, selectedRows) => {
                            openDiagramDetail(selectedRowIds[0] as string);
                        }).build(),
                    GridAction.buttonBuilder(ButtonId.SHOW_DIAGRAM, ActionButtonType.IMMEDIATE, _transl(DiagramTranslationKey.GRID_ACTION_SHOW_DIAGRAM), diagramsMenu.icon as JSX.Element)
                        .onClick((selectedRowIds, selectedRows) => {
                            showDiagramEditorDialog(selectedRowIds[0] as string, false)
                        }).build(),
                    GridAction.buttonBuilder(ButtonId.EDIT_DIAGRAM, ActionButtonType.IMMEDIATE, _transl(DiagramTranslationKey.GRID_ACTION_EDIT_DIAGRAM),
                        <EditIcon/>)
                        .isEnabled(selectedRows => controller.canUpdateAllDiagrams(selectedRows))
                        .onClick((selectedRowIds, selectedRows) => {
                            showDiagramEditorDialog(selectedRowIds[0] as string, true);
                        }).build(),
                    GridAction.buttonBuilder(ButtonId.ADD_DIAGRAM, ActionButtonType.IMMEDIATE, _transl(DiagramTranslationKey.GRID_ACTION_ADD_DIAGRAM),
                        <AddIcon/>)
                        .enabledPolicy(EnabledPolicy.ALWAYS)
                        .isEnabled(selectedRows => canCreateDiagrams())
                        .onClick(() => {
                            showCreateDiagramDialog();
                        }).build(),
                    GridAction.buttonBuilder(ButtonId.DELETE_DIAGRAM, ActionButtonType.IMMEDIATE, _transl(DiagramTranslationKey.GRID_ACTION_DELETE_DIAGRAM),
                        <DeleteIcon/>)
                        .enabledPolicy(EnabledPolicy.WHEN_AT_LEAST_ONE_SELECTED)
                        .isEnabled(selectedRows => controller.canDeleteAllDiagrams(selectedRows))
                        .onClick((selectedRowIds, selectedRows) => {
                            selectedRowIds.length === 1 ? showDeleteDiagramDialog(selectedRowIds[0] as string) :
                                showDeleteDiagramsDialog(selectedRowIds as string[]);
                        }).build(),
                    GridAction.buttonBuilder("DUPLICATE_DIAGRAM", ActionButtonType.IMMEDIATE, _transl(DiagramTranslationKey.GRID_ACTION_DUPLICATE_DIAGRAM),
                        <FileCopy/>)
                        .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                        .isEnabled(selectedRows => canCreateDiagrams())
                        .onClick((selectedRowIds, selectedRows) => openDuplicateDialog(selectedRows[0] as DiagramInfoDto))
                        .build(),
                    GridAction.bulkMenuBuilder("BULK_LABELS", _transl(DiagramTranslationKey.GRID_ACTION_BULK_LABELS),
                        <LocalOffer/>, [
                            GridAction.bulkMenuItemBuilder(ButtonId.ADD_BULK_LABELS, _transl(DiagramTranslationKey.GRID_ACTION_ADD_BULK_LABELS),
                                <AddCircleOutlineIcon/>)
                                .onClick((selectedRowIds, selectedRows) => {
                                    addBulkLabels(selectedRowIds as Array<string>, selectedRows as DiagramInfoDto[]);
                                }).build(),
                            GridAction.bulkMenuItemBuilder(ButtonId.REMOVE_BULK_LABELS, _transl(DiagramTranslationKey.GRID_ACTION_REMOVE_BULK_LABELS),
                                <RemoveCircleOutlineIcon/>)
                                .isEnabled((selectedRows) => getAvailableLabelOptions(selectedRows).length !== 0)
                                .onClick((selectedRowIds, selectedRows) => {
                                    removeBulkLabels(selectedRowIds as Array<string>, selectedRows as DiagramInfoDto[]);
                                }).build(),
                        ]).build(),
                    GridAction.bulkMenuBuilder("BULK_COLLECTIONS", _transl(DiagramTranslationKey.GRID_ACTION_BULK_COLLECTIONS),
                        <Shield/>, [
                            GridAction.bulkMenuItemBuilder(ButtonId.ADD_BULK_COLLECTIONS, _transl(DiagramTranslationKey.GRID_ACTION_ADD_BULK_COLLECTIONS),
                                <AddCircleOutlineIcon/>)
                                .onClick((selectedRowIds, selectedRows) => {
                                    addBulkCollections(selectedRowIds as Array<string>, selectedRows as DiagramInfoDto[]);
                                }).isEnabled((selectedRows) => controller.canUpdateCollections(selectedRows as Array<DiagramInfoDto>)).build(),
                            GridAction.bulkMenuItemBuilder(ButtonId.REMOVE_BULK_COLLECTIONS, _transl(DiagramTranslationKey.GRID_ACTION_REMOVE_BULK_COLLECTIONS),
                                <RemoveCircleOutlineIcon/>)
                                .onClick((selectedRowIds, selectedRows) => {
                                    removeBulkCollections(selectedRowIds as Array<string>, selectedRows as DiagramInfoDto[]);
                                }).isEnabled((selectedRows) => controller.canUpdateCollections(selectedRows as Array<DiagramInfoDto>)).build(),
                        ]).build(),
                    GridAction.bulkMenuBuilder("BULK_OTHERS", "", <MoreHoriz/>, [
                        GridAction.bulkMenuItemBuilder(ButtonId.SHOW_ELEMENTS_LIST, _transl(DiagramTranslationKey.GRID_ACTION_SHOW_ELEMENTS_LIST), <ViewList/>)
                            .onClick((selectedRowIds) => loadDiagramsElementList(selectedRowIds as Array<string>))
                            .build(),
                    ]).build(),
                ]}
                onSelectionChanged={(selectedRowIds: Array<unknown>, selectedRows: Array<unknown>) => {
                    setDiagramAclsFromSelectedRows(selectedRowIds);
                    setSelectedRows(selectedRows as DiagramInfoDto[]);
                }}
                onRowDoubleClick={(params, e) => {
                    openDiagramDetail(params.row.identifier as string);
                }}
                presets={gridPresets}
                onPresetsChanged={onPresetsChanged}
                loading={fetchStatus === FetchStatusType.STARTED}
                peristentStateId={PersistentStateId.DIAGRAMS_PAGE_GRID}
                resourceId={JSON.stringify(filter || {})}
            />
        </React.Fragment>
    )
}
