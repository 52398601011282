import PositionOverlay from "./nodeposition/PositionOverlay";
import PositionHandle, {ResizeHandleType} from "./nodeposition/PositionHandle";
import EventManager, {Unsubscriber} from "../../event/EventManager";
import RenderContext from "../context/RenderContext";
import {
    EventProperty,
    EventType,
    IChartGridSnappingFunctionUpdatedEvent,
    IModelUpdatedOnItemsRemovedEvent,
    IModelUpdatedOnNodesMovedEvent,
    IModelUpdatedOnNodesResizedEvent,
    INodeEvent,
    INodeResizeEvent,
    ISelectionChangedEvent,
    ModelUpdatedOnNodesAlignedEvent, NewNodeShowUpdateLabelEvent,
    NodeRenderedOnNodeCreatedEvent
} from "../../event/Event";
import PositionManagerUtils from "./nodeposition/PositionManagerUtils";
import GeometryUtils, {Area, Point} from "../util/GeometryUtils";
import {GRID_MIN_UNITS_SNAPPING_FUNCTION, ISnappingFunction} from "./GridManager";
import {ModelManager} from "./ModelManager";
import NodeRendererUtils from "../renderer/node/NodeRendererUtils";
import {DiagramEditorUtils, SELECTED_NODE_GROUP_STROKE_WIDTH} from "../util/DiagramEditorUtils";
import * as d3 from "d3";
import EventUtils from "../../event/EventUtils";
import {ArchimateRelationship, ArchimateRelationshipType} from "../../archimate/ArchimateRelationship";
import {PointIncrement} from "../common/PointIncrement";
import {
    DISABLE_ON_NODE_MOVE_POINTER_EVENTS_ALL_CLASS_NAME,
    DISABLE_ON_NODE_MOVE_POINTER_EVENTS_AUTO_CLASS_NAME,
    DISABLE_ON_NODE_MOVE_POINTER_EVENTS_STROKE_CLASS_NAME,
    HANDLE_FILL_COLOR
} from "../common/UIConstants";
import {IDiagramNodeDto} from "../../apis/diagram/IDiagramNodeDto";
import {IDiagramConnectionDto} from "../../apis/diagram/IDiagramConnectionDto";
import {NodeType} from "../../apis/diagram/NodeType";
import {NodeResizeCalculator} from "./nodeposition/NodeResizeCalculator";
import store from "../../../store/Store";
import {RelationshipDto} from "../../apis/relationship/RelationshipDto";


export default class NodePositionManager {

    public static readonly MOVE_STARTED_THRESHOLD = 3;
    public static readonly RESIZE_STARTED_THRESHOLD = 2;

    private selectionOverlay: PositionOverlay;
    private selectionHandle: PositionHandle;

    private eventManager: EventManager;
    private renderContext?: RenderContext;

    private selectedNodes: Array<IDiagramNodeDto>;
    private selectedConnections: Array<IDiagramConnectionDto>;

    private startPointerPoint: Point;
    private pointerPositionIncrement: PointIncrement;

    private snappingFunction?: ISnappingFunction;
    private nodeDimensions: {[id: string]: Area};

    private moveParentCandidate?: IDiagramNodeDto;
    private nodeMovementDraggedNode?: IDiagramNodeDto;

    private nodeResizeCalculator: NodeResizeCalculator;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOVE_STARTED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOVE_IN_PROGRESS, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOVE_FINISHED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOVE_CANCELLED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RESIZE_STARTED, this.handleNodeResizeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RESIZE_IN_PROGRESS, this.handleNodeResizeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RESIZE_FINISHED, this.handleNodeResizeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RESIZE_CANCELLED, this.handleNodeResizeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RENDERED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_NODES_MOVED, this.handleModelUpdatedOnNodesMovedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_NODES_RESIZED, this.handleModelUpdatedOnNodesResizedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_ITEMS_REMOVED, this.handleModelUpdatedOnItemsNodesRemovedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.SELECTION_CHANGED, this.handleSelectionChangedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_GRID_SNAPPING_FUNCTION_UPDATED, this.handleChartGridSnappingFunctionUpdatedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOUSE_ENTER, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOUSE_LEAVE, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RENDERED_ON_NODE_CREATED, this.handleNodeCreateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<ModelUpdatedOnNodesAlignedEvent>(EventType.MODEL_UPDATED_ON_NODES_ALIGNED, this.handleModelUpdatedOnNodesAligned.bind(this)));

        this.selectionOverlay = new PositionOverlay(eventManager);
        this.selectionHandle = new PositionHandle(eventManager);

        this.selectedNodes = [];
        this.selectedConnections = [];
        this.nodeDimensions = {};

        this.startPointerPoint = new Point(0, 0);
        this.pointerPositionIncrement = new PointIncrement(0, 0);

        this.nodeResizeCalculator = new NodeResizeCalculator(store.getState().diagramDefaults);
    }

    destroy() {
        this.selectionHandle.destroy();
        this.selectionOverlay.destroy();

        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
        this.selectionHandle.init(renderContext);
        this.selectionOverlay.init(renderContext);
    }

    private initSelection(node: IDiagramNodeDto) {
        if (this.renderContext) {
            if (this.renderContext.isEditOrPreEdit()) {
                this.selectionHandle.initPositionHandle(node);
                this.selectionOverlay.initPositionOverlay(node);
            }
        }
    }

    // IMPLEMENTATION SHARED BY BOTH MOVEMENT AND RESIZE

    private setStartPointerPoint(event: any) {
        this.startPointerPoint.x = event[EventProperty.TRANSFORMED_X_COORDINATE];
        this.startPointerPoint.y = event[EventProperty.TRANSFORMED_Y_COORDINATE];
    }

    private updateMovementIncrement(event: any) {
        const x = event[EventProperty.TRANSFORMED_X_COORDINATE];
        const y = event[EventProperty.TRANSFORMED_Y_COORDINATE];
        this.pointerPositionIncrement.incrementX = x - this.startPointerPoint.x;
        this.pointerPositionIncrement.incrementY = y - this.startPointerPoint.y;
    }

    private synchronizePosition(nodes: Array<IDiagramNodeDto>) {
        this.selectionOverlay.synchronizePositionOverlays(nodes, this.selectedNodes);
        this.selectionHandle.synchronizePositionHandles(nodes, this.selectedNodes);
    }

    // NODE DELETE RELATED IMPLEMENTATION

    private handleModelUpdatedOnItemsNodesRemovedEvent(event: IModelUpdatedOnItemsRemovedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_ITEMS_REMOVED) {
            event.removedNodes.forEach(node => {
                this.selectionHandle.removePositionHandle(node);
                this.selectionOverlay.removePositionOverlay(node);
            });
        }
    }

    private handleNodeCreateEvent(event: NodeRenderedOnNodeCreatedEvent): void {
        if (this.renderContext) {
            if (event.type === EventType.NODE_RENDERED_ON_NODE_CREATED) {
                const newNode = event.node;
                let diagramNodes = this.renderContext.modelManager.getDiagramNodes();

                const containingNode = diagramNodes.find((node) => {
                    if (newNode.identifier === node.identifier) {
                        return false;
                    }
                    return newNode.x >= node.x &&
                        newNode.x <= node.x + node.w &&
                        newNode.y >= node.y &&
                        newNode.y <= node.y + node.h;
                });

                if (containingNode) {
                    this.showNodeNestingConnectionCreationDialog(newNode, containingNode, event.elementCreated, event.renderedNodesCount, event.isUndoRedoResult);
                } else {
                    this.eventManager.publishEvent<NewNodeShowUpdateLabelEvent>({
                        type: EventType.NEW_NODE_SHOW_UPDATE_LABEL,
                        node: newNode,
                        isUndoRedoResult: event.isUndoRedoResult,
                        elementCreated: event.elementCreated,
                        renderedNodesCount: event.renderedNodesCount
                    });
                }
            }
        }
    }

    private showNodeNestingConnectionCreationDialog(newNode: IDiagramNodeDto, parentCandidate: IDiagramNodeDto, elementCreated: boolean, renderedNodesCount: number, isUndoRedoResult?: boolean) {
        const newNodeArr: Array<IDiagramNodeDto> = [];
        newNodeArr.push(newNode);

        const nodeDimensions: { [id: string]: Area } = {};
        nodeDimensions[newNode.identifier] = Area.from(newNode.x, newNode.y, newNode.w, newNode.h);

        const nodesToConnectWithParent = this.resolveNodesToConnectWithParent(parentCandidate, newNodeArr, true);
        if (nodesToConnectWithParent.length > 0) {
            this.eventManager.publishEvent({
                type: EventType.SHOW_NODES_NESTING_CONNECTION_CREATION_DIALOG,
                parentCandidate: parentCandidate,
                nodesToConnectWithParent: nodesToConnectWithParent,
                isNewNode: true,
                isUndoRedoResult: isUndoRedoResult,
                elementCreated: elementCreated,
                renderedNodesCount: renderedNodesCount,
                selectedNodes: newNodeArr,
                movedNodes: newNodeArr,
                selectedConnections: this.selectedConnections,
                dimensions: nodeDimensions
            });
        } else {
            this.eventManager.publishEvent<NewNodeShowUpdateLabelEvent>({
                type: EventType.NEW_NODE_SHOW_UPDATE_LABEL,
                node: newNode,
                isUndoRedoResult: isUndoRedoResult,
                elementCreated: elementCreated,
                renderedNodesCount: renderedNodesCount
            });
        }
    }

    // MOVEMENT RELATED IMPLEMENTATION

    private handleNodeEvent(event: INodeEvent): void {
        if (this.renderContext) {
            const jsEvent = event.event;

            if (event.type === EventType.NODE_MOVE_STARTED) {
                const draggedNode = event.node;
                this.setStartPointerPoint(jsEvent);
                this.nodeMovementDraggedNode = draggedNode;
                const nodes = this.resolveNodesToBeMoved(this.nodeMovementDraggedNode, this.renderContext);
                const parentNode = this.resolveValidMoveParentCandidate(this.nodeMovementDraggedNode, nodes);
                this.moveParentCandidate = parentNode;
                if (this.moveParentCandidate) {
                    this.addMoveParentHighlight(this.moveParentCandidate);
                }
                this.disableNodeMovePointerEvents(nodes, event);
            }
            if (event.type === EventType.NODE_MOVE_IN_PROGRESS) {
                const draggedNode = event.node;
                this.updateMovementIncrement(jsEvent);
                const nodes = this.resolveNodesToBeMoved(draggedNode, this.renderContext);

                this.updateNodeDimensionsOnNodeMove(draggedNode,  nodes);
                this.selectionOverlay.updatePositionOverlaysOnMove(nodes, this.nodeDimensions);

                this.checkMouseWithinParentCandidate(jsEvent);

                this.eventManager.publishEvent({
                    type: EventType.NODES_POSITION_UPDATE_IN_PROGRESS,
                    nodes: nodes,
                    nodeDimensions: this.nodeDimensions,
                    event: event.event,
                });
            }
            if (event.type === EventType.NODE_MOVE_FINISHED) {
                const draggedNode = event.node;
                this.updateMovementIncrement(jsEvent);
                const nodes = this.resolveNodesToBeMoved(draggedNode, this.renderContext);

                this.updateNodeDimensionsOnNodeMove(draggedNode,  nodes);
                this.selectionOverlay.hidePositionOverlays(nodes);

                const selectedNodes = this.getSelectedOrDraggedNodes();
                this.onNodeMoveFinished(nodes, selectedNodes, event);

                this.clearMoveParentCandidate();
                this.nodeMovementDraggedNode = undefined;
                this.enableNodeMovePointerEvents(nodes, event);

                this.eventManager.publishEvent({
                    type: EventType.NODES_POSITION_UPDATED,
                    nodes: nodes,
                    nodeDimensions: this.nodeDimensions,
                    event: event.event,
                });
            }
            if (event.type === EventType.NODE_MOVE_CANCELLED) {
                const draggedNode = event.node;
                const nodes = this.resolveNodesToBeMoved(draggedNode, this.renderContext);
                this.selectionOverlay.cancelPositionOverlays(nodes);

                this.clearMoveParentCandidate();
                this.nodeMovementDraggedNode = undefined;
                this.enableNodeMovePointerEvents(nodes, event);
            }

            if (event.type === EventType.NODE_MOUSE_ENTER) {
                if (this.nodeMovementDraggedNode) {
                    const nodes = this.resolveNodesToBeMoved(this.nodeMovementDraggedNode, this.renderContext);
                    this.moveParentCandidate = this.resolveValidMoveParentCandidate(event.node, nodes);
                    if (this.moveParentCandidate) {
                        this.addMoveParentHighlight(this.moveParentCandidate);
                    }
                }
            }
            if (event.type === EventType.NODE_MOUSE_LEAVE) {
                const mouseLeaveNode = event.node;
                if (this.nodeMovementDraggedNode) {
                    if (mouseLeaveNode.identifier === this.moveParentCandidate?.identifier) {
                        this.clearMoveParentCandidate();
                    }
                }
            }

            if (event.type === EventType.NODE_RENDERED) {
                const renderedNode = event.node;
                this.initSelection(renderedNode);
            }
        }
    }

    private onNodeMoveFinished(movedNodes: IDiagramNodeDto[], selectedNodes: IDiagramNodeDto[], event: any) {
        const publishNodeMoved = (parentNode?: IDiagramNodeDto, parentNodeNewRelationships?: Array<RelationshipDto>) => {
            this.eventManager.publishEvent({
                type: EventType.NODES_MOVED,
                selectedNodes: selectedNodes,
                movedNodes: movedNodes,
                parentNode: parentNode,
                parentNodeNewRelationships: parentNodeNewRelationships,
                selectedConnections: this.selectedConnections,
                dimensions: this.nodeDimensions
            });
        }

        if (this.moveParentCandidate != null) {
            const nodesToConnectWithParent = this.resolveNodesToConnectWithParent(this.moveParentCandidate, movedNodes, false);
            if (nodesToConnectWithParent.length > 0) {
                // if there's a node without required relationship to the parent
                // show relationship selection dialog
                const parentCandidate = this.moveParentCandidate;
                this.eventManager.publishEvent({
                    type: EventType.SHOW_NODES_NESTING_CONNECTION_CREATION_DIALOG,
                    parentCandidate: parentCandidate,
                    nodesToConnectWithParent: nodesToConnectWithParent,
                    isNewNode: false,
                    selectedNodes: selectedNodes,
                    movedNodes: movedNodes,
                    selectedConnections: this.selectedConnections,
                    dimensions: this.nodeDimensions
                });
            } else {
                publishNodeMoved(this.moveParentCandidate, []);
            }
        } else {
            publishNodeMoved(undefined, undefined)
        }
    }

    private resolveNodesToBeMoved(draggedNode: IDiagramNodeDto, renderContext: RenderContext) {
        const resolvedNodes = new Array<IDiagramNodeDto>();
        let nodesToMove = this.selectedNodes;
        if (this.selectedNodes.length === 0 || this.selectedNodes.indexOf(draggedNode) === -1) {
            // user started to drag another node (such that is not selected)
            nodesToMove = [draggedNode];
        }
        nodesToMove.forEach(node => {
            const candidates = renderContext.modelManager.getChildNodesInclusive(node);
            const selectedNodeArea = Area.fromNode(node);
            // find child nodes completely included within selected node
            const includedNodes = candidates.filter(candidate => candidate.identifier === node.identifier || selectedNodeArea.contains(Area.fromNode(candidate)));
            includedNodes.forEach(includedNode => {
                //node could be in this.selectedNodes -> deduplicate here
                if (resolvedNodes.indexOf(includedNode) === -1) {
                    resolvedNodes.push(includedNode);
                }
            })
        })
        return resolvedNodes;
    }

    private updateNodeDimensionsOnNodeMove(draggedNode: IDiagramNodeDto, nodes: Array<IDiagramNodeDto>) {
        this.nodeDimensions = {};

        // compute new pointerPositionIncrement based on given snapping function
        const updateInfo = PositionManagerUtils.computeUpdateInfoOnNodeMove(draggedNode, this.pointerPositionIncrement, this.snappingFunction as ISnappingFunction);

        // use updated pointerPositionIncrement to update dimensions
        this.nodeDimensions = PositionManagerUtils.computeDimensionsOnNodesMove(nodes, updateInfo.snappedPointerIncrement, GRID_MIN_UNITS_SNAPPING_FUNCTION);
        this.nodeDimensions[draggedNode.identifier] = updateInfo.updatedArea;
    }

    private handleModelUpdatedOnNodesMovedEvent(event: IModelUpdatedOnNodesMovedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_NODES_MOVED) {
            const nodes = event.nodeDimensionsNew.map(nodeDimension => nodeDimension.node);
            if (event.parentNode) {
                nodes.push(event.parentNode);
            }
            this.synchronizePosition(nodes);
        }
    }


    // RESIZE RELATED IMPLEMENTATION


    private handleNodeResizeEvent(event: INodeResizeEvent) {
        if (this.renderContext) {
            const jsEvent = event.event;
            const node = event.node;
            const resizeType = event.resizeType;

            if (event.type === EventType.NODE_RESIZE_STARTED) {
                this.setStartPointerPoint(jsEvent);
            }
            if (event.type === EventType.NODE_RESIZE_IN_PROGRESS) {
                this.updateMovementIncrement(jsEvent);
                const nodes = this.resolveNodesToBeResized(node, this.renderContext);

                this.updateNodeDimensionsOnNodeResize(node, nodes, resizeType, this.renderContext.modelManager);
                this.selectionOverlay.updatePositionOverlaysOnResize(nodes, this.nodeDimensions);

                this.eventManager.publishEvent({
                    type: EventType.NODES_POSITION_UPDATE_IN_PROGRESS,
                    nodes: nodes,
                    nodeDimensions: this.nodeDimensions,
                    event: event.event,
                });
            }
            if (event.type === EventType.NODE_RESIZE_FINISHED) {
                this.updateMovementIncrement(jsEvent);
                const nodes = this.resolveNodesToBeResized(node, this.renderContext);

                this.updateNodeDimensionsOnNodeResize(node, nodes, resizeType, this.renderContext.modelManager);
                this.selectionOverlay.hidePositionOverlays(nodes);

                this.eventManager.publishEvent({type: EventType.NODES_RESIZED, nodes: nodes, dimensions: this.nodeDimensions});
            }
            if (event.type === EventType.NODE_RESIZE_CANCELLED) {
                const nodes = this.resolveNodesToBeResized(node, this.renderContext);
                this.selectionOverlay.cancelPositionOverlays(nodes);
            }
        }
    }

    private resolveNodesToBeResized(node: IDiagramNodeDto, renderContext: RenderContext) {
        return this.selectedNodes;
    }

    private updateNodeDimensionsOnNodeResize(draggedNode: IDiagramNodeDto, nodes: Array<IDiagramNodeDto>, resizeType: ResizeHandleType, modelManager: ModelManager) {
        this.nodeDimensions = {};

        // compute new pointerPositionIncrement based on given snapping function
        const updateInfo = this.nodeResizeCalculator.computeUpdateInfoOnNodeResize(draggedNode, resizeType, this.pointerPositionIncrement, this.snappingFunction as ISnappingFunction, modelManager);

        // use updated pointerPositionIncrement to update dimensions
        this.nodeDimensions = this.nodeResizeCalculator.computeDimensionsOnNodesResize(nodes, resizeType, updateInfo.snappedPointerIncrement, GRID_MIN_UNITS_SNAPPING_FUNCTION, modelManager);
        this.nodeDimensions[draggedNode.identifier] = updateInfo.updatedArea;
    }

    private handleModelUpdatedOnNodesResizedEvent(event: IModelUpdatedOnNodesResizedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_NODES_RESIZED) {
            this.synchronizePosition(event.nodeDimensionsNew.map(dimensions => dimensions.node));
        }
    }

    // SELECTION CHANGE IMPLEMENTATION

    private handleSelectionChangedEvent(event: ISelectionChangedEvent) {
        if (event.type === EventType.SELECTION_CHANGED) {
            this.selectedNodes = event.selectedNodes;
            this.selectedConnections = event.selectedConnections;
            this.selectionHandle.showPositionHandles(event.newlySelectedNodes);
            this.selectionHandle.hidePositionHandles(event.newlyDeselectedNodes);
        }
    }

    private handleChartGridSnappingFunctionUpdatedEvent(event: IChartGridSnappingFunctionUpdatedEvent) {
        this.snappingFunction = event.snappingFunction;
    }

    private areAllConnectedWithParent(children: IDiagramNodeDto[], parent: IDiagramNodeDto) {
        return false;
    }

    private removeMoveParentHighlight(moveParentCandidate: IDiagramNodeDto) {
        NodeRendererUtils.removeNodePointerEventsRectStroke(moveParentCandidate);
    }

    private addMoveParentHighlight(moveParentCandidate: IDiagramNodeDto) {
        NodeRendererUtils.setNodePointerEventsRectStroke(moveParentCandidate, HANDLE_FILL_COLOR, SELECTED_NODE_GROUP_STROKE_WIDTH);
    }

    private resolveValidMoveParentCandidate(parentCandidate: IDiagramNodeDto, nodes: IDiagramNodeDto[]): IDiagramNodeDto | undefined {
        const movedNodesHierarchyIds = nodes.flatMap(node => (this.renderContext as RenderContext).modelManager.getChildNodesInclusive(node))
            .map(node => node.identifier);

        let parentFound = false;
        let parent: IDiagramNodeDto | undefined = parentCandidate;
        while (!parentFound) {
            if (this.isValidParentCandidate(parent as IDiagramNodeDto, movedNodesHierarchyIds)) {
                parentFound = true;
            } else {
                parent = this.renderContext?.modelManager.getParentNode(parent as IDiagramNodeDto);
                if (parent == null) {
                    parentFound = true;
                }
            }
        }
        return parent;
    }

    private isValidParentCandidate(parentCandidate: IDiagramNodeDto, movedNodesHierarchyIds: Array<string>) {
        return !DiagramEditorUtils.isLabelNodeType(parentCandidate) &&
            !DiagramEditorUtils.isJunctionNodeType(parentCandidate, this.renderContext?.modelManager as ModelManager) &&
            movedNodesHierarchyIds.indexOf(parentCandidate.identifier) === -1
    }

    private resolveNodesToConnectWithParent(parentCandidate: IDiagramNodeDto, nodes: IDiagramNodeDto[], isNewNode: boolean) {
        let nodesToConnectWithParent: Array<IDiagramNodeDto> = [];
        if (parentCandidate.elementIdentifier) {
            const modelManager = (this.renderContext?.modelManager as ModelManager);
            const parentElement = modelManager.getElementById(parentCandidate.elementIdentifier);

            // new relationship should be created only between selected nodes (or dragged node) but
            // nodes array contains whole hierarchy -> filter out nodes that should not be connected
            if (isNewNode) {
                nodesToConnectWithParent.push(...nodes);
            } else {
                nodesToConnectWithParent.push(...this.getSelectedOrDraggedNodes());
            }
            nodesToConnectWithParent = nodesToConnectWithParent.filter(node => nodes.indexOf(node) !== -1);

            // filter out non-element nodes (i.e. labels, containers and nodes without association to existing element)
            nodesToConnectWithParent = nodesToConnectWithParent.filter(node => node.type !== NodeType.LABEL && node.type !== NodeType.CONTAINER && node.elementIdentifier != null);

            // filter out nodes that are already connected with parentCandidate using aggregation or composition or specialization
            const requiredRelationshipStandardNames = [
                ...ArchimateRelationship[ArchimateRelationshipType.AGGREGATION].standardNames,
                ...ArchimateRelationship[ArchimateRelationshipType.COMPOSITION].standardNames,
                ...ArchimateRelationship[ArchimateRelationshipType.SPECIALIZATION].standardNames,
            ]
            nodesToConnectWithParent = nodesToConnectWithParent
                .filter(node => {
                    // do not show dialog when parent didn't change
                    return modelManager.getParentNode(node) !== parentCandidate;
                })
                .filter(node => {
                    const nodeElement = modelManager.getElementById(node.elementIdentifier as string);
                    const existingRelationships = modelManager.getRelationshipsBetweenElements(parentElement, nodeElement)
                        .filter(relationship => requiredRelationshipStandardNames.indexOf(relationship.type) !== -1);
                    return existingRelationships.length === 0;
                })
        }
        return nodesToConnectWithParent;
    }

    private getSelectedOrDraggedNodes() {
        const nodes = [];
        nodes.push(...this.selectedNodes);
        if (this.nodeMovementDraggedNode != null && nodes.indexOf(this.nodeMovementDraggedNode) === -1) {
            nodes.push(this.nodeMovementDraggedNode);
        }
        return nodes;
    }

    private clearMoveParentCandidate() {
        if (this.moveParentCandidate) {
            this.removeMoveParentHighlight(this.moveParentCandidate);
            this.moveParentCandidate = undefined;
        }
    }

    private disableNodeMovePointerEvents(nodes: Array<IDiagramNodeDto>, event: any) {
        const pointerEventsAuto = DISABLE_ON_NODE_MOVE_POINTER_EVENTS_AUTO_CLASS_NAME;
        const pointerEventsAll = DISABLE_ON_NODE_MOVE_POINTER_EVENTS_ALL_CLASS_NAME;
        const pointerEventsStroke = DISABLE_ON_NODE_MOVE_POINTER_EVENTS_STROKE_CLASS_NAME;
        d3.selectAll(`.${pointerEventsAuto}, .${pointerEventsAll}, .${pointerEventsStroke}`)
            .attr("pointer-events", "none");

        this.eventManager.publishEvent({
            type: EventType.NODES_POINTER_EVENTS_DISABLE,
            nodes: nodes,
            event: event.event,
        });
    }

    private enableNodeMovePointerEvents(nodes: Array<IDiagramNodeDto>, event: any) {
        d3.selectAll("."+DISABLE_ON_NODE_MOVE_POINTER_EVENTS_AUTO_CLASS_NAME).attr("pointer-events", "auto");
        d3.selectAll("."+DISABLE_ON_NODE_MOVE_POINTER_EVENTS_ALL_CLASS_NAME).attr("pointer-events", "all");
        d3.selectAll("."+DISABLE_ON_NODE_MOVE_POINTER_EVENTS_STROKE_CLASS_NAME).attr("pointer-events", "stroke");

        this.eventManager.publishEvent({
            type: EventType.NODES_POINTER_EVENTS_ENABLE,
            nodes: nodes,
            event: event.event,
        })
    }

    private checkMouseWithinParentCandidate(jsEvent: any) {
        if (this.moveParentCandidate != null) {
            const coords = EventUtils.getTransformedCoordinates(jsEvent);
            const parentArea = new Area(this.moveParentCandidate.x, this.moveParentCandidate.y, this.moveParentCandidate.w, this.moveParentCandidate.h);
            if (!GeometryUtils.areaContainsPoint(parentArea, new Point(coords[0], coords[1]))) {
                this.clearMoveParentCandidate();
            }
        }
    }

    private handleModelUpdatedOnNodesAligned(event: ModelUpdatedOnNodesAlignedEvent) {
        this.synchronizePosition(event.nodes);
    }
}
