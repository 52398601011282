import {ResizeHandleType} from "../diagrameditor/manager/nodeposition/PositionHandle";
import {ISnappingFunction} from "../diagrameditor/manager/GridManager";
import {Area, Point} from "../diagrameditor/util/GeometryUtils";
import {NewConnectionDefinition} from "../diagrameditor/manager/ConnectionCreateManager";
import {ElementDefinition} from "../diagrameditor/manager/ElementCreateManager";
import {IDiagramNodeDto} from "../apis/diagram/IDiagramNodeDto";
import {IDiagramConnectionDto} from "../apis/diagram/IDiagramConnectionDto";
import {PointIncrement} from "../diagrameditor/common/PointIncrement";
import {NodeType} from "../apis/diagram/NodeType";
import {RelationshipDto} from "../apis/relationship/RelationshipDto";
import {IDiagramPoint} from "../apis/diagram/IDiagramPoint";
import {ElementDto} from "../apis/element/ElementDto";
import {AlignmentType} from "../diagrameditor/common/AlignmentType";
import {NodeDimensionsType} from "./NodeDimensionsType";
import ModelAccessor from "../diagrameditor/manager/model/ModelAccessor";
import {DiagramNode, Model} from "../diagrameditor/model/Model";

export interface Event {
    readonly type: string,
    isUndoRedoResult?: boolean,
}

export type EventTypeKey<E extends Event> = E["type"];

export enum EventProperty {
    TRANSFORMED_X_COORDINATE = "__EVENT_PROPERTY_TRANSFORMED_X_COORDINATE__",
    TRANSFORMED_Y_COORDINATE = "__EVENT_PROPERTY_TRANSFORMED_Y_COORDINATE__",
}

export type NodeSyntheticAttributeNameType = "forbidden" | "highlighted";

export enum EventType {
    // NODE EVENTS
    NODE_MOVE_STARTED = "NODE_MOVE_STARTED",
    NODE_MOVE_IN_PROGRESS = "NODE_MOVE_IN_PROGRESS",
    NODE_MOVE_FINISHED = "NODE_MOVE_FINISHED",
    NODE_MOVE_CANCELLED = "NODE_MOVE_CANCELLED",
    NODE_RENDERED = "NODE_RENDERED",
    NODE_MOUSE_ENTER = "NODE_MOUSE_ENTER",
    NODE_MOUSE_LEAVE = "NODE_MOUSE_LEAVE",
    NODE_MOUSE_CLICKED = "NODE_MOUSE_CLICKED",
    NODE_MOUSE_DOWN = "NODE_MOUSE_DOWN",
    NODE_SHOW_CONTEXT_MENU = "NODE_SHOW_CONTEXT_MENU",
    NODE_MOUSE_UP = "NODE_MOUSE_UP",
    NODE_DBLCLICK = "NODE_DBLCLICK",
    CONNECTION_DBLCLICK = "CONNECTION_DBLCLICK",
    NODE_CONNECTION_CREATE_BY_HANDLE_ACTIVATED = "NODE_CONNECTION_CREATE_BY_HANDLE_ACTIVATED",
    NODE_CONNECTION_CREATE_BY_HANDLE_IN_PROGRESS = "NODE_CONNECTION_CREATE_BY_HANDLE_IN_PROGRESS",
    NODE_CONNECTION_CREATE_BY_HANDLE_FINISHED = "NODE_CONNECTION_CREATE_BY_HANDLE_FINISHED",
    NODE_CONNECTION_CREATE_BY_HANDLE_CANCELLED = "NODE_CONNECTION_CREATE_BY_HANDLE_CANCELLED",
    SHOW_NODES_NESTING_CONNECTION_CREATION_DIALOG = "SHOW_NODES_NESTING_CONNECTION_CREATION_DIALOG",
    // NODE MOVED EVENTS
    NODES_MOVED = "NODES_MOVED",
    MODEL_UPDATED_ON_NODES_MOVED = "MODEL_UPDATED_ON_NODES_MOVED",
    // NODE RESIZE EVENTS
    NODE_RESIZE_STARTED = "NODE_RESIZE_STARTED",
    NODE_RESIZE_IN_PROGRESS = "NODE_RESIZE_IN_PROGRESS",
    NODE_RESIZE_FINISHED = "NODE_RESIZE_FINISHED",
    NODE_RESIZE_CANCELLED = "NODE_RESIZE_CANCELLED",
    // NODES RESIZED EVENTS
    NODES_RESIZED = "NODES_RESIZED",
    MODEL_UPDATED_ON_NODES_RESIZED = "MODEL_UPDATED_ON_NODES_RESIZED",
    // CONNECTION EVENTS
    CONNECTION_RENDERED = "CONNECTION_RENDERED",
    HIDDEN_CONNECTION_RENDERED = "HIDDEN_CONNECTION_RENDERED",
    CONNECTION_MOUSE_ENTER = "CONNECTION_MOUSE_ENTER",
    HIDDEN_CONNECTION_MOUSE_ENTER = "HIDDEN_CONNECTION_MOUSE_ENTER",
    CONNECTION_MOUSE_LEAVE = "CONNECTION_MOUSE_LEAVE",
    HIDDEN_CONNECTION_MOUSE_LEAVE = "HIDDEN_CONNECTION_MOUSE_LEAVE",
    CONNECTION_MOUSE_DOWN = "CONNECTION_MOUSE_DOWN",
    HIDDEN_CONNECTION_MOUSE_DOWN = "HIDDEN_CONNECTION_MOUSE_DOWN",
    CONNECTION_MOUSE_CLICKED = "CONNECTION_MOUSE_CLICKED",
    HIDDEN_CONNECTION_MOUSE_CLICKED = "HIDDEN_CONNECTION_MOUSE_CLICKED",
    SHOW_HIDDEN_CONNECTION_BY_HANDLE = "SHOW_HIDDEN_CONNECTION_BY_HANDLE",
    HIDDEN_CONNECTION_SHOW_CONTEXT_MENU = "HIDDEN_CONNECTION_SHOW_CONTEXT_MENU",
    CONNECTION_SHOW_CONTEXT_MENU = "CONNECTION_SHOW_CONTEXT_MENU",
    CONNECTION_CREATE_HANDLE_MOUSE_LEAVE = "CONNECTION_CREATE_HANDLE_MOUSE_LEAVE",
    // CONNECTION BENDPOINT EVENTS
    CONNECTION_BENDPOINT_REMOVE_MOUSE_ENTER = "CONNECTION_BENDPOINT_REMOVE_MOUSE_ENTER",
    CONNECTION_BENDPOINT_REMOVE_MOUSE_LEAVE = "CONNECTION_BENDPOINT_REMOVE_MOUSE_LEAVE",
    CONNECTION_BENDPOINT_MOUSE_ENTER = "CONNECTION_BENDPOINT_MOUSE_ENTER",
    CONNECTION_BENDPOINT_MOUSE_LEAVE = "CONNECTION_BENDPOINT_MOUSE_LEAVE",
    CONNECTION_BENDPOINT_MOVE_STARTED = "CONNECTION_BENDPOINT_MOVE_STARTED",
    CONNECTION_BENDPOINT_MOVE_IN_PROGRESS = "CONNECTION_BENDPOINT_MOVE_IN_PROGRESS",
    CONNECTION_BENDPOINT_MOVE_FINISHED = "CONNECTION_BENDPOINT_MOVE_FINISHED",
    CONNECTION_BENDPOINT_MOVE_CANCELLED = "CONNECTION_BENDPOINT_MOVE_CANCELLED",
    CONNECTION_BENDPOINT_CREATE_STARTED = "CONNECTION_BENDPOINT_CREATE_STARTED",
    CONNECTION_BENDPOINT_CREATE_IN_PROGRESS = "CONNECTION_BENDPOINT_CREATE_IN_PROGRESS",
    CONNECTION_BENDPOINT_CREATE_FINISHED = "CONNECTION_BENDPOINT_CREATE_FINISHED",
    CONNECTION_BENDPOINT_CREATE_CANCELLED = "CONNECTION_BENDPOINT_CREATE_CANCELLED",
    CONNECTION_BENDPOINT_REMOVE_REQUESTED = "CONNECTION_BENDPOINT_REMOVE_REQUESTED",
    MODEL_UPDATED_ON_CONNECTION_BENDPOINT_REMOVED = "MODEL_UPDATED_ON_CONNECTION_BENDPOINT_REMOVED",
    // CONNECTION BENDPOINT MOVED EVENTS
    CONNECTION_BENDPOINT_MOVED = "CONNECTION_BENDPOINT_MOVED",
    MODEL_UPDATED_ON_CONNECTION_BENDPOINT_MOVED = "MODEL_UPDATED_ON_CONNECTION_BENDPOINT_MOVED",
    // CONNECTION BENDPOINT CREATED EVENTS
    CONNECTION_BENDPOINT_CREATED = "CONNECTION_BENDPOINT_CREATED",
    MODEL_UPDATED_ON_CONNECTION_BENDPOINT_CREATED = "MODEL_UPDATED_ON_CONNECTION_BENDPOINT_CREATED",
    // CHART EVENTS
    CHART_RENDERED = "CHART_RENDERED",
    CHART_NODES_RERENDERED = "CHART_NODES_RERENDERED",
    CHART_CONNECTIONS_RERENDERED = "CHART_CONNECTIONS_RERENDERED",
    CHART_ZOOM_IN_CLICKED = "CHART_ZOOM_IN_CLICKED",
    CHART_ZOOM_OUT_CLICKED = "CHART_ZOOM_OUT_CLICKED",
    CHART_ZOOM_SCALE_TO_FIT_CLICKED = "CHART_ZOOM_SCALE_TO_FIT_CLICKED",
    CHART_MOUSE_ENTER = "CHART_MOUSE_ENTER",
    CHART_MOUSE_UP = "CHART_MOUSE_UP",
    CHART_MOUSE_DOWN = "CHART_MOUSE_DOWN",
    CHART_MOUSE_LEAVE = "CHART_MOUSE_LEAVE",
    CHART_MOUSE_CLICKED = "CHART_MOUSE_CLICKED",
    CHART_MOUSE_MOVE = "CHART_MOUSE_MOVE",
    CHART_CANVAS_DRAG_STARTED = "CHART_CANVAS_DRAG_STARTED",
    CHART_CANVAS_DRAG_IN_PROGRESS = "CHART_CANVAS_DRAG_IN_PROGRESS",
    CHART_CANVAS_DRAG_FINISHED = "CHART_CANVAS_DRAG_FINISHED",
    CHART_CANVAS_DRAG_CANCELLED = "CHART_CANVAS_DRAG_CANCELLED",
    CHART_CANVAS_CLICKED = "CHART_CANVAS_CLICKED",
    CHART_GRID_SHOW = "CHART_GRID_SHOW",
    CHART_GRID_HIDE = "CHART_GRID_HIDE",
    CHART_GRID_SNAP = "CHART_GRID_SNAP",
    CHART_GRID_DO_NOT_SNAP = "CHART_GRID_DO_NOT_SNAP",
    CHART_SAVED = "CHART_SAVED",
    // GRID SNAPPING FUNCTION
    CHART_GRID_SNAPPING_FUNCTION_UPDATED = "CHART_GRID_SNAPPING_FUNCTION_UPDATED",
    // GRID GRID UPDATE UNITS EVENT
    CHART_GRID_UPDATE_UNITS = "CHART_GRID_UPDATE_UNITS",
    CHART_KEYDOWN = "CHART_KEYDOWN",
    CHART_KEYUP = "CHART_KEYUP",
    // ITEM CREATE MENU
    NODE_CREATE_MENU_ACTIVATED = "NODE_CREATE_MENU_ACTIVATED",
    ELEMENT_CREATE_MENU_DEACTIVATED = "ELEMENT_CREATE_MENU_DEACTIVATED",
    // SELECTION EVENTS
    SELECTION_CHANGED = "SELECTION_CHANGED",
    HIDDEN_CONNECTION_SELECTION_CHANGED = "HIDDEN_CONNECTION_SELECTION_CHANGED",
    // CHART_RESIZED
    CHART_RESIZED = "CHART_RESIZED",
    // CHART SCROLLED
    CHART_SCROLLED = "CHART_SCROLLED",
    MODEL_UPDATED = "MODEL_UPDATED",
    NAVIGATOR_SCROLLED = "NAVIGATOR_SCROLLED",
    // NODE LABEL UPDATE
    NODE_LABEL_UPDATE = "NODE_LABEL_UPDATE",
    NODE_LABEL_UPDATE_CANCELLED = "NODE_LABEL_UPDATE_CANCELLED",
    MODEL_UPDATED_ON_NODE_LABEL_UPDATE = "MODEL_UPDATED_ON_NODE_LABEL_UPDATE",
    // CONNECTION LABEL UPDATE
    CONNECTION_LABEL_UPDATE = "CONNECTION_LABEL_UPDATE",
    MODEL_UPDATED_ON_CONNECTION_LABEL_UPDATE = "MODEL_UPDATED_ON_CONNECTION_LABEL_UPDATE",
    NODE_CREATE = "NODE_CREATE",
    MODEL_UPDATED_ON_ITEMS_CREATED = "MODEL_UPDATED_ON_ITEMS_CREATED",
    NODE_RENDERED_ON_NODE_CREATED = "NODE_RENDERED_ON_NODE_CREATED",
    NEW_NODE_SHOW_UPDATE_LABEL = "NEW_NODE_SHOW_UPDATE_LABEL",
    CONNECTION_CREATE = "CONNECTION_CREATE",
    MODEL_UPDATED_ON_CONNECTION_CREATED = "MODEL_UPDATED_ON_CONNECTION_CREATED",
    DIAGRAM_ZOOM_UPDATED = "DIAGRAM_ZOOM_UPDATED",
    MODEL_UPDATED_ON_CONNECTION_REMOVED = "MODEL_UPDATED_ON_CONNECTION_REMOVED",
    UNDO_REDO_UPDATED = "UNDO_REDO_UPDATED",
    MODEL_UPDATED_ON_ITEMS_REMOVED = "MODEL_UPDATED_ON_ITEMS_REMOVED",
    NODES_POINTER_EVENTS_ENABLE = "NODES_POINTER_EVENTS_ENABLE",
    NODES_POINTER_EVENTS_DISABLE = "NODES_POINTER_EVENTS_DISABLE",
    MODEL_INITIALIZED = "MODEL_INITIALIZED",
    SCROLL_BY_REQUEST = "SCROLL_BY_REQUEST",
    SCROLL_TO_REQUEST = "SCROLL_TO_REQUEST",
    DIAGRAM_GROUP_MOVE_BY_REQUEST = "DIAGRAM_GROUP_MOVE_BY_REQUEST",
    DIAGRAM_GROUP_MOVE_TO_REQUEST = "DIAGRAM_GROUP_MOVE_TO_REQUEST",
    NODES_POSITION_UPDATE_IN_PROGRESS = "NODES_POSITION_UPDATE_IN_PROGRESS",
    NODES_POSITION_UPDATED = "NODES_POSITION_UPDATED",
    SVG_PAPER_AREA_UPDATED = "SVG_PAPER_AREA_UPDATED",
    STYLES_UPDATED = "STYLES_UPDATED",
    HIDDEN_CONNECTIONS_UPDATED = "HIDDEN_CONNECTIONS_UPDATED",
    CONNECTION_HANDLES_INIT = "CONNECTION_HANDLES_INIT",
    CONNECTION_HANDLES_SHOW = "CONNECTION_HANDLES_SHOW",
    CONNECTION_HANDLES_UPDATE = "CONNECTION_HANDLES_UPDATE",
    CONNECTION_HANDLES_HIDE = "CONNECTION_HANDLES_HIDE",
    CONNECTION_HANDLES_REMOVE = "CONNECTION_HANDLES_REMOVE",
    HIDDEN_CONNECTION_HANDLES_SHOW = "HIDDEN_CONNECTION_HANDLES_SHOW",
    HIDDEN_CONNECTION_HANDLES_HIDE = "HIDDEN_CONNECTION_HANDLES_HIDE",
    CONNECTION_OVERLAY_INIT = "CONNECTION_OVERLAY_INIT",
    CONNECTION_OVERLAY_BENDPOINT_UPDATE = "CONNECTION_OVERLAY_BENDPOINT_UPDATE",
    CONNECTION_OVERLAY_REMOVE = "CONNECTION_OVERLAY_REMOVE",
    HIDDEN_CONNECTION_VISUALIZE = "HIDDEN_CONNECTION_VISUALIZE",
    HIDDEN_CONNECTION_VISUALIZED = "HIDDEN_CONNECTION_VISUALIZED",
    ADD_ELEMENTS_TO_MODEL = "ADD_ELEMENTS_TO_MODEL",
    REMOVE_SELECTED_OBJECTS = "REMOVE_SELECTED_OBJECTS",
    ALIGN_NODES = "ALIGN_NODES",
    MODEL_UPDATED_ON_NODES_ALIGNED = "MODEL_UPDATED_ON_NODES_ALIGNED",
    ON_DELETE_SELECTED_ITEMS_MENU_ACTIVATED = "ON_DELETE_SELECTED_ITEMS_MENU_ACTIVATED",
    UPDATE_NODES_POSITION = "UPDATE_NODES_POSITION",
    MODEL_UPDATED_ON_NODES_POSITION_UPDATED = "MODEL_UPDATED_ON_NODES_POSITION_UPDATED",
    MODEL_UPDATED_ON_MODEL_BACKUP_LOADED = "MODEL_UPDATED_ON_MODEL_BACKUP_LOADED",
    NODE_SYNTHETIC_ATTRIBUTE_UPDATED = "NODE_SYNTHETIC_ATTRIBUTE_UPDATED",
    PAPER_SHOW_CONTEXT_MENU = "PAPER_SHOW_CONTEXT_MENU",
}

export type NodeEventType = EventType.NODE_MOVE_STARTED | EventType.NODE_MOVE_IN_PROGRESS | EventType.NODE_MOVE_FINISHED | EventType.NODE_MOVE_CANCELLED
    | EventType.NODE_MOUSE_ENTER | EventType.NODE_MOUSE_LEAVE | EventType.NODE_MOUSE_CLICKED | EventType.NODE_MOUSE_DOWN | EventType.NODE_MOUSE_UP | EventType.NODE_DBLCLICK
    | EventType.NODE_RENDERED | EventType.NODE_SHOW_CONTEXT_MENU | EventType.NODE_CONNECTION_CREATE_BY_HANDLE_ACTIVATED | EventType.NODE_CONNECTION_CREATE_BY_HANDLE_IN_PROGRESS
    | EventType.NODE_CONNECTION_CREATE_BY_HANDLE_FINISHED | EventType.NODE_CONNECTION_CREATE_BY_HANDLE_CANCELLED;

export type ScrollRequestEventType = EventType.SCROLL_BY_REQUEST | EventType.SCROLL_TO_REQUEST;

export type NodesPositionUpdateEventType = EventType.NODES_POSITION_UPDATE_IN_PROGRESS | EventType.NODES_POSITION_UPDATED;

export type SvgPaperAreaUpdatedEventType = EventType.SVG_PAPER_AREA_UPDATED;

export type DiagramGroupMoveRequestEventType = EventType.DIAGRAM_GROUP_MOVE_BY_REQUEST | EventType.DIAGRAM_GROUP_MOVE_TO_REQUEST;

export type ConnectionEventType = EventType.CONNECTION_MOUSE_ENTER | EventType.CONNECTION_MOUSE_LEAVE | EventType.CONNECTION_MOUSE_DOWN | EventType.CONNECTION_MOUSE_CLICKED
    | EventType.CONNECTION_RENDERED | EventType.HIDDEN_CONNECTION_RENDERED | EventType.HIDDEN_CONNECTION_MOUSE_ENTER
    | EventType.HIDDEN_CONNECTION_MOUSE_LEAVE | EventType.HIDDEN_CONNECTION_MOUSE_CLICKED | EventType.HIDDEN_CONNECTION_MOUSE_DOWN | EventType.SHOW_HIDDEN_CONNECTION_BY_HANDLE
    | EventType.HIDDEN_CONNECTION_SHOW_CONTEXT_MENU | EventType.CONNECTION_SHOW_CONTEXT_MENU | EventType.CONNECTION_DBLCLICK | EventType.CONNECTION_CREATE_HANDLE_MOUSE_LEAVE;

export type ModelUpdatedOnConnectionRemovedEventType = EventType.MODEL_UPDATED_ON_CONNECTION_REMOVED;

export type ChartEventType = EventType.CHART_RENDERED | EventType.CHART_CONNECTIONS_RERENDERED | EventType.CHART_NODES_RERENDERED | EventType.CHART_ZOOM_IN_CLICKED | EventType.CHART_ZOOM_OUT_CLICKED
    | EventType.CHART_MOUSE_ENTER | EventType.CHART_MOUSE_LEAVE | EventType.CHART_MOUSE_UP | EventType.CHART_MOUSE_DOWN | EventType.CHART_MOUSE_MOVE | EventType.CHART_MOUSE_CLICKED | EventType.CHART_CANVAS_DRAG_STARTED | EventType.CHART_CANVAS_DRAG_IN_PROGRESS
    | EventType.CHART_CANVAS_DRAG_FINISHED | EventType.CHART_CANVAS_DRAG_CANCELLED | EventType.CHART_CANVAS_CLICKED | EventType.CHART_GRID_SNAP | EventType.CHART_GRID_DO_NOT_SNAP
    | EventType.CHART_GRID_SHOW | EventType.CHART_GRID_HIDE | EventType.CHART_KEYDOWN | EventType.CHART_KEYUP | EventType.CHART_SAVED
    | EventType.CHART_ZOOM_SCALE_TO_FIT_CLICKED;

export type DiagramZoomEventType = EventType.DIAGRAM_ZOOM_UPDATED;

export type ChartScrolledEventType = EventType.CHART_SCROLLED;

export type ChartResizedEventType = EventType.CHART_RESIZED;

export type ChartGridUnitsUpdateEventType = EventType.CHART_GRID_UPDATE_UNITS;

export type ChartGridSnappingFunctionUpdatedEventType = EventType.CHART_GRID_SNAPPING_FUNCTION_UPDATED;

export type NodesMovedEventType = EventType.NODES_MOVED;
export type ShowNodesNestingConnectionCreationDialogEventType = EventType.SHOW_NODES_NESTING_CONNECTION_CREATION_DIALOG;

export type ModelUpdatedOnNodesMovedEventType = EventType.MODEL_UPDATED_ON_NODES_MOVED;

export type ItemCreateMenuEventType = EventType.NODE_CREATE_MENU_ACTIVATED | EventType.ELEMENT_CREATE_MENU_DEACTIVATED;

export type ConnectionCreateEventType = EventType.CONNECTION_CREATE;

export type NodeResizeEventType = EventType.NODE_RESIZE_STARTED | EventType.NODE_RESIZE_IN_PROGRESS | EventType.NODE_RESIZE_FINISHED | EventType.NODE_RESIZE_CANCELLED;

export type NodesResizedEventType = EventType.NODES_RESIZED;

export type ModelUpdatedOnNodesResizedEventType = EventType.MODEL_UPDATED_ON_NODES_RESIZED;

export type BendpointEventType = EventType.CONNECTION_BENDPOINT_MOUSE_ENTER | EventType.CONNECTION_BENDPOINT_MOUSE_LEAVE |
    EventType.CONNECTION_BENDPOINT_REMOVE_MOUSE_ENTER | EventType.CONNECTION_BENDPOINT_REMOVE_MOUSE_LEAVE;

export type BendpointMoveEventType = EventType.CONNECTION_BENDPOINT_MOVE_STARTED | EventType.CONNECTION_BENDPOINT_MOVE_IN_PROGRESS | EventType.CONNECTION_BENDPOINT_MOVE_FINISHED
    | EventType.CONNECTION_BENDPOINT_MOVE_CANCELLED;

export type BendpointCreateEventType = EventType.CONNECTION_BENDPOINT_CREATE_STARTED | EventType.CONNECTION_BENDPOINT_CREATE_IN_PROGRESS | EventType.CONNECTION_BENDPOINT_CREATE_FINISHED
    | EventType.CONNECTION_BENDPOINT_CREATE_CANCELLED;

export type BendpointMovedEventType = EventType.CONNECTION_BENDPOINT_MOVED;

export type ModelUpdatedOnBendpointMovedEventType = EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_MOVED;

export type BendpointCreatedEventType = EventType.CONNECTION_BENDPOINT_CREATED;

export type ModelUpdatedOnBendpointCreatedEventType = EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_CREATED;

export type BendpointRemoveEventType = EventType.CONNECTION_BENDPOINT_REMOVE_REQUESTED;

export type ModelUpdatedOnBendpointRemovedEventType = EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_REMOVED;

export type SelectionChangedEventType = EventType.SELECTION_CHANGED;

export type ModelUpdatedEventType = EventType.MODEL_UPDATED;

export type NavigatorScrolledEventType = EventType.NAVIGATOR_SCROLLED;

export type NodeLabelUpdateEventType = EventType.NODE_LABEL_UPDATE;
export type NodeLabelUpdateCancelledEventType = EventType.NODE_LABEL_UPDATE_CANCELLED;
export type ConnectionLabelUpdateEventType = EventType.CONNECTION_LABEL_UPDATE;

export type ModelUpdatedOnNodeLabelUpdateEventType = EventType.MODEL_UPDATED_ON_NODE_LABEL_UPDATE;
export type ModelUpdatedOnConnectionLabelUpdateEventType = EventType.MODEL_UPDATED_ON_CONNECTION_LABEL_UPDATE;

export type NodeCreateEventType = EventType.NODE_CREATE;

export type ModelUpdatedOnItemsCreatedEventType = EventType.MODEL_UPDATED_ON_ITEMS_CREATED;

export type ModelUpdatedOnConnectionCreatedEventType = EventType.MODEL_UPDATED_ON_CONNECTION_CREATED;

export type UndoRedoEventType = EventType.UNDO_REDO_UPDATED;

export type ModelUpdatedOnItemsRemovedEventType = EventType.MODEL_UPDATED_ON_ITEMS_REMOVED;

export type NodesPointerEventsEventType = EventType.NODES_POINTER_EVENTS_ENABLE | EventType.NODES_POINTER_EVENTS_DISABLE;

export type ConnectionsEventType = EventType.HIDDEN_CONNECTIONS_UPDATED;

export type ModelBackupEventType = EventType.MODEL_UPDATED_ON_MODEL_BACKUP_LOADED;

export type NodeSyntheticAttributeUpdatedEventType = EventType.NODE_SYNTHETIC_ATTRIBUTE_UPDATED;

export type PaperShowContextMenuEventType = EventType.PAPER_SHOW_CONTEXT_MENU;


export enum UndoRedoType {
    UNDO = "UNDO",
    REDO = "REDO",
}

export interface IUndoableRedoableEvent extends Event {
    description: string,
    undo: () => void,
    redo: () => void,
    undoRedoType?: UndoRedoType,
}

// dom event based events -> e.g. based on mouse movements, clicks, key presses etc.
export interface IDomBasedEvent extends Event {
    event: any,
}

// derived events, typically based on (or initiated by) dom events -> e.g. graph model changes based on dom events fired during interactions with UI
export interface ISyntheticEvent extends Event {
}

export interface INodeEvent extends IDomBasedEvent {
    type: NodeEventType,
    node: IDiagramNodeDto,
    removeFromModel?: boolean,
    transformedClientCoordinates: [number, number],
}

export interface IScrollRequestEvent extends ISyntheticEvent {
    type: ScrollRequestEventType,
    isAutoScroll?: boolean,
    causeEvent?: EventType,
    top: number,
    left: number,
}

export interface ShowNodesNestingConnectionCreationDialogEvent extends Event {
    type: EventType.SHOW_NODES_NESTING_CONNECTION_CREATION_DIALOG,
    parentCandidate: IDiagramNodeDto,
    nodesToConnectWithParent: Array<IDiagramNodeDto>,
    isNewNode: true,
    isUndoRedoResult: boolean,
    elementCreated: boolean,
    renderedNodesCount: number,
    selectedNodes: Array<IDiagramNodeDto>,
    movedNodes: Array<IDiagramNodeDto>,
    selectedConnections: Array<IDiagramConnectionDto>,
    dimensions: { [id: string]: Area }
}

export interface INodesPositionUpdateEvent extends IDomBasedEvent {
    type: NodesPositionUpdateEventType,
    nodes: Array<IDiagramNodeDto>,
    nodeDimensions: {[id: string]: Area},
}

export interface ISvgPaperAreaUpdatedEvent extends ISyntheticEvent {
    type: SvgPaperAreaUpdatedEventType,
    causeEvent: Event,
    previousPaperArea: Area,
    newPaperArea: Area,
}

export interface IDiagramGroupMoveRequestEvent extends ISyntheticEvent {
    type: DiagramGroupMoveRequestEventType,
    top: number,
    left: number,
}

export interface INodesPointerEventsEvent extends IDomBasedEvent {
    type: NodesPointerEventsEventType,
    nodes: Array<IDiagramNodeDto>,
}

export interface IConnectionEvent extends IDomBasedEvent {
    type: ConnectionEventType,
    connection: IDiagramConnectionDto,
    removeFromModel?: boolean,
    removedRelationship?: RelationshipDto
}

export interface IModelUpdatedOnConnectionRemovedEvent extends ISyntheticEvent, IUndoableRedoableEvent {
    type: ModelUpdatedOnConnectionRemovedEventType,
    relationship?: RelationshipDto,
    isRelationshipNew: boolean,
    connection: IDiagramConnectionDto,
}

export interface BendpointEvent extends IDomBasedEvent {
    type: BendpointEventType,
    connection: IDiagramConnectionDto,
    bendpointIndex: number,
}

export interface IBendpointMoveEvent extends IDomBasedEvent {
    type: BendpointMoveEventType,
    connection: IDiagramConnectionDto,
    bendpointIndex: number,
}

export interface IBendpointRemoveEvent extends IDomBasedEvent {
    type: BendpointRemoveEventType,
    connection: IDiagramConnectionDto,
    bendpointIndex: number,
}

export interface IModelUpdatedOnBendpointRemovedEvent extends ISyntheticEvent, IUndoableRedoableEvent {
    type: ModelUpdatedOnBendpointRemovedEventType,
    connection: IDiagramConnectionDto,
    bendpointIndex: number,
}

export interface IBendpointCreateEvent extends IDomBasedEvent {
    type: BendpointCreateEventType,
    connection: IDiagramConnectionDto,
    bendpointIndex: number;
    bendpoint: [number, number],
}

export interface IBendpointMovedEvent extends IDomBasedEvent {
    type: BendpointMovedEventType,
    connection: IDiagramConnectionDto,
    bendpointIndex: number;
    increment: PointIncrement;
}

export interface IModelUpdatedOnBendpointMovedEvent extends ISyntheticEvent, IUndoableRedoableEvent {
    type: ModelUpdatedOnBendpointMovedEventType,
    connection: IDiagramConnectionDto,
    bendpointIndex: number;
    bendpointOld: IDiagramPoint,
    bendpointNew: IDiagramPoint,
}

export interface IBendpointCreatedEvent extends IDomBasedEvent {
    type: BendpointCreatedEventType,
    connection: IDiagramConnectionDto,
    bendpointIndex: number;
    bendpoint: [number, number];
}

export interface IModelUpdatedOnBendpointCreatedEvent extends ISyntheticEvent, IUndoableRedoableEvent {
    type: ModelUpdatedOnBendpointCreatedEventType,
    connection: IDiagramConnectionDto,
    bendpointIndex: number;
    bendpoint: [number, number];
}

export interface INodesMovedEvent extends ISyntheticEvent {
    type: NodesMovedEventType,
    selectedNodes: Array<IDiagramNodeDto>,
    movedNodes: Array<IDiagramNodeDto>,
    parentNode?: IDiagramNodeDto,
    parentNodeNewRelationships?: Array<RelationshipDto>,
    selectedConnections: Array<IDiagramConnectionDto>,
    dimensions: {[id: string]: Area}
}

export interface IModelUpdatedOnNodesMovedEvent extends ISyntheticEvent, IUndoableRedoableEvent {
    type: ModelUpdatedOnNodesMovedEventType,
    parentNode: IDiagramNodeDto | undefined,
    nodeDimensionsOld: Array<NodeDimensionsType>,
    nodeDimensionsNew: Array<NodeDimensionsType>,
    connectionBendpointsOld: Array<ConnectionBendpointType>,
    connectionBendpointsNew: Array<ConnectionBendpointType>,
    connectionsToAdd: Array<IDiagramConnectionDto>,
    connectionsToRemove: Array<IDiagramConnectionDto>,
}

export type NodeParentPositionType = {
    node: IDiagramNodeDto,
    parent: IDiagramNodeDto | undefined,
    index: number,
}

export type ConnectionBendpointType = {
    connection: IDiagramConnectionDto,
    bendpointIndex: number,
    bendpoint: IDiagramPoint,
}

export interface INodeResizeEvent extends IDomBasedEvent {
    type: NodeResizeEventType,
    node: IDiagramNodeDto,
    resizeType: ResizeHandleType,
}

export interface INodesResizedEvent extends ISyntheticEvent {
    type: NodesResizedEventType,
    nodes: Array<IDiagramNodeDto>,
    dimensions: {[id: string]: Area},
}

export interface IModelUpdatedOnNodesResizedEvent extends ISyntheticEvent, IUndoableRedoableEvent {
    type: ModelUpdatedOnNodesResizedEventType,
    nodeDimensionsOld: Array<NodeDimensionsType>,
    nodeDimensionsNew: Array<NodeDimensionsType>,
    nodes?: IDiagramNodeDto[],
}

export interface IChartEvent extends IDomBasedEvent {
    type: ChartEventType,
}

export interface DiagramZoomEvent extends IDomBasedEvent {
    type: DiagramZoomEventType,
    actualZoom: number,
    previousZoom: number,
    minZoom: number,
    maxZoom: number,
}

export interface IChartScrolledEvent extends IDomBasedEvent {
    type: ChartScrolledEventType,
    scrollHeight: number,
    scrollWidth: number,
    clientHeight: number,
    clientWidth: number,
    scrollTop: number,
    scrollLeft: number,
    clientArea: Area,
    svgArea: Area,
    paperArea: Area,
    nodesConnectionsOverlaysArea: Area,
    paperToSvgRelativeArea: Area,
    diagramGroupToSvgRelativeArea: Area,
}

export interface IChartGridUnitsUpdateEvent extends IDomBasedEvent {
    type: ChartGridUnitsUpdateEventType,
    units: number,
}

export interface IChartGridSnappingFunctionUpdatedEvent extends IDomBasedEvent {
    type: ChartGridSnappingFunctionUpdatedEventType,
    snappingFunction: ISnappingFunction,
}

export interface IChartResizedEvent extends ISyntheticEvent {
    type: ChartResizedEventType,
    area: Area,
}

export interface ISelectionChangedEvent extends IDomBasedEvent {
    type: SelectionChangedEventType,
    selectedNodes: Array<IDiagramNodeDto>,
    selectedConnections: Array<IDiagramConnectionDto>,
    newlySelectedNodes: Array<IDiagramNodeDto>,
    newlyDeselectedNodes: Array<IDiagramNodeDto>,
    newlySelectedConnections: Array<IDiagramConnectionDto>,
    newlyDeselectedConnections: Array<IDiagramConnectionDto>,
}

export interface IModelUpdatedEvent extends ISyntheticEvent {
    type: ModelUpdatedEventType,
    reasonType: string,
    model: Model,
    undoRedoType?: UndoRedoType,
}

export interface INavigatorScrolledEvent extends IDomBasedEvent {
    type: NavigatorScrolledEventType,
    increment: Point,
}

export interface INodeLabelUpdateEvent extends ISyntheticEvent {
    type: NodeLabelUpdateEventType,
    node: IDiagramNodeDto,
    label: string,
    oldLabel?: string,
    isNodeCreateResult?: boolean,
}

export interface INodeLabelUpdateCancelledEvent extends ISyntheticEvent {
    type: NodeLabelUpdateCancelledEventType,
}

export interface IConnectionLabelUpdateEvent extends ISyntheticEvent {
    type: ConnectionLabelUpdateEventType,
    connection: IDiagramConnectionDto,
    label: string,
    oldLabel?: string,
    isConnectionCreateResult?: boolean,
}

export interface IModelUpdatedOnNodeLabelUpdateEvent extends ISyntheticEvent, IUndoableRedoableEvent {
    type: ModelUpdatedOnNodeLabelUpdateEventType,
    node: IDiagramNodeDto,
    nodeLabelOld: string,
    nodeLabelNew: string,
    isNodeCreateResult?: boolean,
}

export interface IModelUpdatedOnConnectionLabelUpdateEvent extends ISyntheticEvent, IUndoableRedoableEvent {
    type: ModelUpdatedOnConnectionLabelUpdateEventType,
    connection: IDiagramConnectionDto,
    connectionLabelOld: string,
    connectionLabelNew: string,
    isConnectionCreateResult?: boolean,
}

export interface IItemCreateMenuEvent extends IDomBasedEvent {
    type: ItemCreateMenuEventType,
    nodeType?: NodeType,
    elementDefinition?: ElementDefinition,
}

export interface INodeCreateEvent extends IDomBasedEvent {
    type: NodeCreateEventType,
    nodeType: NodeType,
    nodeId: string,
    elementDefiniton: ElementDefinition,
    diagramGroupPoint: Point,
}

export interface NodeRenderedOnNodeCreatedEvent extends ISyntheticEvent {
    type: EventType.NODE_RENDERED_ON_NODE_CREATED,
    node: IDiagramNodeDto,
    elementCreated: boolean,
    renderedNodesCount: number;
}

export interface NewNodeShowUpdateLabelEvent extends ISyntheticEvent {
    type: EventType.NEW_NODE_SHOW_UPDATE_LABEL;
    node: IDiagramNodeDto;
    elementCreated: boolean,
    renderedNodesCount: number;
}

export interface IModelUpdatedOnItemsCreatedEvent extends ISyntheticEvent, IUndoableRedoableEvent {
    type: ModelUpdatedOnItemsCreatedEventType,
    createdNodes: Array<IDiagramNodeDto>,
    createdElements: Array<ElementDto>,
    createdConnections: Array<IDiagramConnectionDto>,
    createdRelationships: Array<RelationshipDto>,
    isNewElement: boolean,
}

export interface IConnectionCreateEvent extends IDomBasedEvent {
    type: ConnectionCreateEventType,
    connectionDefinition: NewConnectionDefinition,
    firstNode: IDiagramNodeDto,
    secondNode: IDiagramNodeDto
}

export interface IModelUpdatedOnConnectionCreatedEvent extends ISyntheticEvent, IUndoableRedoableEvent {
    type: ModelUpdatedOnConnectionCreatedEventType,
    relationship?: RelationshipDto,
    isRelationshipNew: boolean,
    connection: IDiagramConnectionDto,
    isNestedConnection: boolean,
}

export interface IUndoRedoEvent extends ISyntheticEvent {
    type: UndoRedoEventType,
    undoEvents: Array<string>,
    redoEvents: Array<string>,
    saveNeeded: boolean,
    undoRedoType?: UndoRedoType,
}

export interface IModelUpdatedOnItemsRemovedEvent extends ISyntheticEvent, IUndoableRedoableEvent {
    type: ModelUpdatedOnItemsRemovedEventType,
    removedNodes: Array<IDiagramNodeDto>,
    removedElements: Array<ElementDto>,
    removedConnections: Array<IDiagramConnectionDto>,
    removedRelationships: Array<RelationshipDto>,
}

export interface ConnectionsEvent extends ISyntheticEvent {
    type: ConnectionsEventType,
    connections: Array<IDiagramConnectionDto>,
}

export interface ConnectionHandlesInitEvent extends ISyntheticEvent {
    type: EventType.CONNECTION_HANDLES_INIT,
    initialisedConnections: Array<IDiagramConnectionDto>,
    selectedConnections: Array<IDiagramConnectionDto>,
}

export interface ConnectionHandlesShowEvent extends ISyntheticEvent {
    type: EventType.CONNECTION_HANDLES_SHOW,
    connections: Array<IDiagramConnectionDto>,
    bendpointsOnly?: boolean,
}

export interface ConnectionHandlesHideEvent extends ISyntheticEvent {
    type: EventType.CONNECTION_HANDLES_HIDE,
    connections: Array<IDiagramConnectionDto>,
    bendpointsOnly?: boolean,
}

export interface ConnectionHandlesUpdateEvent extends ISyntheticEvent {
    type: EventType.CONNECTION_HANDLES_UPDATE,
    updatedConnections: Array<IDiagramConnectionDto>,
    selectedConnections: Array<IDiagramConnectionDto>,
}

export interface ConnectionHandlesRemoveEvent extends ISyntheticEvent {
    type: EventType.CONNECTION_HANDLES_REMOVE,
    removedConnections: Array<IDiagramConnectionDto>,
}

export interface ConnectionOverlayInitEvent extends ISyntheticEvent {
    type: EventType.CONNECTION_OVERLAY_INIT,
    initialisedConnections: Array<IDiagramConnectionDto>,
    selectedConnections: Array<IDiagramConnectionDto>,
}

export interface ConnectionOverlayBendpointUpdateEvent extends ISyntheticEvent {
    type: EventType.CONNECTION_OVERLAY_BENDPOINT_UPDATE,
    connection: IDiagramConnectionDto,
    bendpointIndex: number,
    positionIncrement: PointIncrement,
    isNewBendpoint: boolean
}

export interface ConnectionOverlayRemoveEvent extends ISyntheticEvent {
    type: EventType.CONNECTION_OVERLAY_REMOVE,
    removedConnections: Array<IDiagramConnectionDto>,
}

export interface HiddenConnectionSelectionChangedEvent extends ISyntheticEvent {
    type: EventType.HIDDEN_CONNECTION_SELECTION_CHANGED,
    selectedConnection: IDiagramConnectionDto | null,
    deselectedConnection: IDiagramConnectionDto | null,
}

export interface HiddenConnectionHandlesShowEvent extends ISyntheticEvent {
    type: EventType.HIDDEN_CONNECTION_HANDLES_SHOW,
    connection: IDiagramConnectionDto,
}

export interface HiddenConnectionHandlesHideEvent extends ISyntheticEvent {
    type: EventType.HIDDEN_CONNECTION_HANDLES_HIDE,
    connection: IDiagramConnectionDto,
}

export interface HiddenConnectionVisualizeEvent extends ISyntheticEvent {
    type: EventType.HIDDEN_CONNECTION_VISUALIZE,
    connection: IDiagramConnectionDto,
}

export interface HiddenConnectionVisualizedEvent extends ISyntheticEvent {
    type: EventType.HIDDEN_CONNECTION_VISUALIZED,
    connectionId: string,
    hiddenConnectionId: string,
}

export interface AddElementsToModelEvent extends ISyntheticEvent {
    type: EventType.ADD_ELEMENTS_TO_MODEL,
    elements: Array<ElementDto>,
}

export interface RemoveSelectedItemsEvent extends ISyntheticEvent {
    type: EventType.REMOVE_SELECTED_OBJECTS,
    nodes: Array<IDiagramNodeDto>,
    connections: Array<IDiagramConnectionDto>,
    removeElementsAndRelationships: boolean,
}

export interface AlignNodesEvent extends ISyntheticEvent {
    type: EventType.ALIGN_NODES;
    alignmentType: AlignmentType,
    alignmentTarget?: IDiagramNodeDto,
    nodes: Array<IDiagramNodeDto>,
}

export interface ModelUpdatedOnNodesAlignedEvent extends ISyntheticEvent, IUndoableRedoableEvent {
    type: EventType.MODEL_UPDATED_ON_NODES_ALIGNED,
    alignmentType: AlignmentType,
    alignmentTarget?: IDiagramNodeDto,
    nodes: Array<IDiagramNodeDto>,
}

export interface OnDeleteSelectedItemsMenuActivatedEvent extends ISyntheticEvent {
    type: EventType.ON_DELETE_SELECTED_ITEMS_MENU_ACTIVATED,
}

export interface ModelBackupEvent extends ISyntheticEvent, IUndoableRedoableEvent {
    type: ModelBackupEventType,
    oldModelAccessor: ModelAccessor,
    newModelAccessor: ModelAccessor,
    nodesToRemove: Array<IDiagramNodeDto>,
    nodesToAdd: Array<IDiagramNodeDto>,
    connectionsToRemove: Array<IDiagramConnectionDto>,
    connectionsToAdd: Array<IDiagramConnectionDto>,
}

export interface NodeSyntheticAttributeUpdatedEvent extends ISyntheticEvent {
    type: NodeSyntheticAttributeUpdatedEventType,
    attributeNames: Array<NodeSyntheticAttributeNameType>,
    nodes: Array<DiagramNode>,
}

export interface IPaperShowContextMenuEvent extends IDomBasedEvent {
    type: PaperShowContextMenuEventType,
    transformedClientCoordinates: [number, number],
}
